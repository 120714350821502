import React from "react";
// import components
import SliderComponent from "../components/SliderComponent";
import MyCardComponent from "../components/MyCardComponent";
import OurCustomerComponent from "../components/OurCustomerComponent";
import AboutMeComponent from "../components/AboutMeComponent";
import FooterComponent from "../components/FooterComponent";
import LastNewsComponent from "../components/LastNewsComponent";

// import PricingComponent from "../components/PricingComponent";
// import OurTeamComponent from "../components/OurTeamComponent";
// import start from bootstrap
import * as ReactBootStrap from "react-bootstrap";

function HomeScreen() {
  const userAuthData = localStorage.getItem("userAuthData", "userId");

  return (
    <div style={{ paddingTop: "30px" }}>
      <center>
        <ReactBootStrap.Container>
          {/* Slider  */}
          <SliderComponent />
          {/* Cards */}
          <h1 style={{ paddingTop: 20, color: "#fedade" }}>Services</h1>
          <MyCardComponent userAuthData={userAuthData} />
          {/* Customer  */}
          <OurCustomerComponent userAuthData={userAuthData} />
          {/* Our Services */}
          <AboutMeComponent userAuthData={userAuthData} />
          {/* Add Our Tem Comp */}
          {/* <OurTeamComponent /> */}
          {/* add Last news Comp */}
          <LastNewsComponent />
          {/* Add Footer Comp */}
          <FooterComponent userAuthData={userAuthData} />
        </ReactBootStrap.Container>
      </center>
    </div>
  );
}

export default HomeScreen;
