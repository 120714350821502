import React, { useEffect, useState } from "react";
import styles from "./styles/ContactUsStyle.module.css";
// Need Get Fetch sever files
import { axios } from "../util/axios";
import { Modal, Button, Alert } from "react-bootstrap";

import Contact from "../models/Contact";
import EditContact from "../components/EditContact";

function ContactUsComponent(props) {
  const userAuthData = localStorage.getItem("userAuthData", "userId");
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showEditAlert, setshowEditAlert] = useState(false);

  const [isShowEditModal, setIsShowEditModal] = useState(false);

  // Fetch Data from sever
  const fetchData = async () => {
    let response = await axios.get("contact/get-all-contacts");
    let result = response.data;
    // console.log(result);
    setCards(result);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const showEditModalHandler = () => {
    setIsShowEditModal(true);
  };
  const closeEditModalHandler = () => {
    setIsShowEditModal(false);
    setSelectedCard(null);
  };
  const selectCardHandler = (card) => {
    setSelectedCard(card);
    showEditModalHandler();
  };

  const NeweditCustomerHandler = async (
    contactId,
    description,
    address,
    number,
    email
  ) => {
    const mycustomer = new Contact(description, address, number, email);
    try {
      let response = await mycustomer.edit(
        contactId,
        userAuthData.replace(/['"]+/g, "")
      );
      let result = response.data;
      console.log(result);
      // window.alert(result.title + " " + result.description);
      setshowEditAlert(true);
      closeEditModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };

  return (
    <div className="container my-5 py-5 z-depth-1">
      {/* Alert Edited */}
      {showEditAlert ? (
        <div style={{ marginBottom: "10vh" }}>
          <Alert
            style={{
              margin: "2vh",
            }}
            variant="success"
            onClose={() => setshowEditAlert(false)}
            dismissible
          >
            <Alert.Heading>Your Table Edited Successfully</Alert.Heading>
            <h2></h2>
          </Alert>
        </div>
      ) : null}
      {/* Alert Edited */}
      {/* Modal Part Start */}
      {cards.map((card) => (
        <Modal
          show={isShowEditModal}
          onHide={closeEditModalHandler}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              {selectedCard ? selectedCard.title : ""}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* EditCard */}
            <EditContact
              NeweditCustomerHandler={NeweditCustomerHandler}
              selectedCard={selectedCard}
            />
            {/* EditCard */}
          </Modal.Body>
        </Modal>
      ))}
      {/* Modal Part End */}
      {cards.map((card) => (
        <section
          className="text-center px-md-5 mx-md-5 dark-grey-text"
          style={{
            backgroundColor: "#fedade",
            padding: "5vh",
            borderRadius: "5vh",
            marginTop: "-4vh",
          }}
        >
          {userAuthData ? (
            <center style={{ margin: "2vh" }}>
              <Button
                variant="warning"
                onClick={() => selectCardHandler(card)}
                style={{
                  // backgroundColor: "#F6A9A9",
                  paddingLeft: "20%",
                  paddingRight: "20%",
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  borderRadius: "4vh",
                  cursor: "pointer",
                }}
              >
                Edit Contact Us Page
              </Button>
            </center>
          ) : null}
          {/* End Edit Admin button For card */}
          <h3 className="font-weight-bold mb-4">Contact Us</h3>

          <p className="text-center w-responsive mx-auto mb-5">
            {card.description}
          </p>

          <div className="row">
            {/* Edit Admin Button For card */}

            {/* <div className="col-md-9 mb-md-0 mb-5">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <input
                        type="text"
                        id="contact-name"
                        class="form-control"
                      />
                      <label for="contact-name" class="">
                        Your name
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <input
                        type="text"
                        id="contact-email"
                        class="form-control"
                      />
                      <label for="contact-email" class="">
                        Your email
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="md-form mb-0">
                      <input
                        type="text"
                        id="contact-Subject"
                        class="form-control"
                      />
                      <label for="contact-Subject" class="">
                        Subject
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="md-form">
                      <textarea
                        id="contact-message"
                        class="form-control md-textarea"
                        rows="3"
                      ></textarea>
                      <label for="contact-message">Your message</label>
                    </div>
                  </div>
                </div>
              </form>

              <div class="text-center text-md-left">
                <a
                  style={{
                    paddingLeft: "8vh",
                    paddingRight: "8vh",
                    margin: "1vh",
                    marginTop: "2vh",
                  }}
                  class="btn btn-info btn-md btn-rounded"
                >
                  Send
                </a>
              </div>
            </div> */}

            <div class="col-md-5 text-center m-auto">
              <ul class="list-unstyled mb-0">
                <li>
                  <i class="fas fa-map-marker-alt fa-2x blue-text"></i>
                  <p> {card.address}</p>
                </li>
                <li>
                  <i class="fas fa-phone fa-2x mt-4 blue-text"></i>
                  <p> {card.number}</p>
                </li>
                <li>
                  <i class="fas fa-envelope fa-2x mt-4 blue-text"></i>
                  <p class="mb-0"> {card.email}</p>
                </li>
              </ul>
            </div>
          </div>
        </section>
      ))}
    </div>
  );
}

export default ContactUsComponent;
