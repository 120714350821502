import { axios } from "../util/axios";

class Header {
  constructor(title, image) {
    this.title = title;
    this.image = image;
  }
  create = (token) => {
    const fd = new FormData();

    fd.append("title", this.title);
    fd.append("image", this.image);

    return axios.post(
      "header/create-header",
      // {
      //   name: this.name,
      //   price: this.price,
      //   isAvail: this.isAvail,
      // },
      fd,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  edit = (headerId, token) => {
    const fd = new FormData();

    fd.append("title", this.title);
    fd.append("image", this.image);

    return axios.patch(
      `header/edit-header/${headerId}`,
      // {
      //   price: this.price,
      //   isAvail: this.isAvail,
      // },
      fd,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  delete = (headerId, token) => {
    return axios.delete(`header/delete-header/${headerId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  };
}

export default Header;
