import React, { useState, useEffect } from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import CreateAbout from "../components/CreateAbout";
import EditAbout from "./EditAbout";
import About from "./../models/About";

import { axios } from "../util/axios";
import { apiUrl } from "../env-vars";

import styles from "./styles/AboutMeStyle.module.css";
import logo from "../assets/img/AboutMe.png"; // relative path to image

function AboutMeComponent({ userAuthData }) {
  const [isShowLoginForm, setIsShowLoginForm] = useState(false);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [showEditAlert, setshowEditAlert] = useState(false);

  // Fetch Data from sever
  const fetchData = async () => {
    let response = await axios.get("about/get-all-abouts");
    let result = response.data;
    // console.log(result);
    setCards(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const showModalHandler = () => {
    setIsShowLoginForm(true);
  };
  const closeModalHandler = () => {
    setIsShowLoginForm(false);
  };

  const showEditModalHandler = () => {
    setIsShowEditModal(true);
  };
  const closeEditModalHandler = () => {
    setIsShowEditModal(false);
    setSelectedCard(null);
  };
  const selectCardHandler = (card) => {
    setSelectedCard(card);
    showEditModalHandler();
  };
  const NewAboutHandler = async (aboutdescription, image) => {
    const product = new About(aboutdescription, image);
    try {
      let response = await product.create(userAuthData.replace(/['"]+/g, ""));
      let result = response.data;
      console.log(result);
      window.alert(result.aboutdescription + " " + result.aboutdescription);
      closeEditModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };
  const NeweditAboutHandler = async (aboutId, aboutdescription, image) => {
    const mycard = new About(aboutdescription, image);
    try {
      let response = await mycard.edit(
        aboutId,
        userAuthData.replace(/['"]+/g, "")
      );
      let result = response.data;
      console.log(result);
      // window.alert(result.title + " " + result.description);
      setshowEditAlert(true);
      closeEditModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };
  const pathCorrector = (path) => {
    return path.replace(/\\/g, "/");
  };
  return (
    <>
      {/* Alert Edited */}
      {showEditAlert ? (
        <div>
          <Alert
            style={{
              margin: "2vh",
            }}
            variant="success"
            onClose={() => setshowEditAlert(false)}
            dismissible
          >
            <Alert.Heading>About Me Edited Successfully</Alert.Heading>
            <h2></h2>
          </Alert>
        </div>
      ) : null}
      {/* Alert Edited */}
      {/* Creaete  Modal Part Start */}

      <Modal show={isShowLoginForm} onHide={closeModalHandler} className="rtl">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateAbout NewAboutHandler={NewAboutHandler} />
        </Modal.Body>
      </Modal>

      {/* Create Modal Part End */}
      {/* Edit Modal Part Start */}

      <Modal
        show={isShowEditModal}
        onHide={closeEditModalHandler}
        className="rtl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit yout About me secrion here</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditAbout
            NeweditAboutHandler={NeweditAboutHandler}
            selectedCard={selectedCard}
          />
        </Modal.Body>
      </Modal>

      {/*Edit  Modal Part End */}
      {/* New About Me section */}
      {/* <Button style={{ margin: "1vh" }} onClick={showModalHandler}>
        create a new about me section with image
      </Button> */}
      {/* End New About Me section */}
      <div className="container">
        <h2 className={styles.title}>About Us</h2>
        <div className="divider-lg"></div>
        {cards.map((card) => (
          <div className="col-md-10 col-lg-9">
            {/* Edit Admin Button For card */}

            {userAuthData ? (
              <Button
                variant="warning"
                onClick={() => selectCardHandler(card)}
                style={{
                  // backgroundColor: "#F6A9A9",
                  paddingLeft: "20%",
                  paddingRight: "20%",
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  borderRadius: "4vh",
                  cursor: "pointer",
                  marginBottom: "3vh",
                }}
              >
                Edit About Me
              </Button>
            ) : null}
            <br />
            {card.image ? (
              <img
                src={apiUrl + "/" + pathCorrector(card.image)}
                className="img-fluid shadow-4"
                alt="..."
                width="200vh"
                height="200vh"
                id={styles.logo}
              />
            ) : null}
            <a href="about" style={{ textDecoration: "none" }}>
              <div className={styles.paragrap}>
                <p className={styles.tagparagraf}>{card.aboutdescription}</p>
              </div>
            </a>

            {/* className={styles.paragrap} */}
            {/* Start Edit And Modal */}
          </div>
        ))}
      </div>
    </>
  );
}

export default AboutMeComponent;
