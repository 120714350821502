import React, { useState, useEffect } from "react";
import * as ReactBootStrap from "react-bootstrap";
import { Button, Modal, Image, Alert, Carousel } from "react-bootstrap";

import { axios } from "../util/axios";
import { apiUrl } from "../env-vars";
import Header from "./../models/Header";

// import styles from "./styles/sliderStyle.module.css";
import CreateHeader from "./CreateHeader";

function SliderComponent(props) {
  const userAuthData = localStorage.getItem("userAuthData", "userId");
  const [isShowLoginForm, setIsShowLoginForm] = useState(false);
  const [cards, setCards] = useState([]);
  const [showCreateAlert, setshowCreateAlert] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Fetch Data from sever
  const fetchData = async () => {
    let response = await axios.get("header/get-all-headers");
    let result = response.data;
    // console.log(result);
    setCards(result);
  };
  useEffect(() => {
    fetchData();
  }, []);
  // add handllers funstion
  const showModalHandler = () => {
    setIsShowLoginForm(true);
  };

  const closeModalHandler = () => {
    setIsShowLoginForm(false);
  };
  // new header image and titel create handler function
  const NewHeaderHandler = async (title, image) => {
    const header = new Header(title, image);
    console.log(header);

    try {
      let response = await header.create(userAuthData.replace(/['"]+/g, ""));
      let result = response.data;
      console.log(result);
      // window.alert(result.title + " " + result.title);
      setshowCreateAlert(true);

      closeModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };
  const deleteHeaderHandler = async (headerId) => {
    const header = new Header();
    try {
      let response = await header.delete(
        headerId,
        userAuthData.replace(/['"]+/g, "")
      );
      let result = response.data;
      // window.alert(result.title + " با موفقیت حذف شد");
      handleClose();

      closeModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };
  const pathCorrector = (path) => {
    return path.replace(/\\/g, "/");
  };
  return (
    <div>
      {/* Alert For Delete */}
      {cards.map((card) => (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete {card.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo,Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => deleteHeaderHandler(card._id)}
            >
              Yes Im Sure
            </Button>
          </Modal.Footer>
        </Modal>
      ))}
      {/* Alert for Delete */}
      {/* Alert Create */}
      {showCreateAlert ? (
        <div>
          <Alert
            style={{
              margin: "2vh",
            }}
            variant="success"
            onClose={() => setshowCreateAlert(false)}
            dismissible
          >
            <Alert.Heading>Your Image Created Successfully</Alert.Heading>
            <h2></h2>
          </Alert>
        </div>
      ) : null}
      {/* Alert Create */}
      {userAuthData ? (
        <center style={{ margin: "4vhs" }}>
          <Button
            onClick={showModalHandler}
            style={{
              position: "relative",
              top: "1vh",
              margin: "2vh",
            }}
            variant="success"
          >
            Create New image Slider
          </Button>
          <hr></hr>
        </center>
      ) : null}

      {/* Modal Part Start */}

      <Modal show={isShowLoginForm} onHide={closeModalHandler} className="rtl">
        <Modal.Header closeButton>
          <Modal.Title>new blog post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateHeader NewHeaderHandler={NewHeaderHandler} />
        </Modal.Body>
      </Modal>
      {/* Test  */}

      {/* Test  */}
      {/* Modal Part End */}

      <ReactBootStrap.Carousel
        nextIcon=">"
        nextLabel=">"
        prevIcon="<"
        prevLabel="<"
      >
        {cards.map((card) => (
          <ReactBootStrap.Carousel.Item>
            {userAuthData ? (
              <Button
                onClick={handleShow}
                style={{
                  margin: "1vh",
                }}
                variant="danger"
              >
                Delete This Image {card.title}
              </Button>
            ) : null}
            <Image
              style={{
                height: "420px",
                // objectFit: "scale-down",
                objectFit: "cover",
              }}
              className="d-block w-100"
              src={apiUrl + "/" + pathCorrector(card.image)}
              alt="First slide"
            />
          </ReactBootStrap.Carousel.Item>
        ))}
      </ReactBootStrap.Carousel>
    </div>
  );
}

export default SliderComponent;
