import React, { useState, useEffect } from "react";
import styles from "./styles/LastNewsStyle.module.css";
import { axios } from "../util/axios";
import { apiUrl } from "../env-vars";
import { Link } from "react-router-dom";

function LastNewsComponent(props) {
  const [cards, setCards] = useState([]);

  // Fetch Data from sever
  const fetchData = async () => {
    let response = await axios.get("blog/get-lasttree-blogposts");
    let result = response.data;
    // console.log(result);
    setCards(result);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const pathCorrector = (path) => {
    return path.replace(/\\/g, "/");
  };
  return (
    <section class="">
      <h3
        class="text-center font-weight-bold mb-5"
        style={{ color: "#fedade", fontSize: "4vh", paddingTop: "3vh" }}
      >
        Latest news/Blog
      </h3>

      <div class="row">
        {/* Start blog post */}
        {cards.map((card) => (
          <div class="col-lg-4 col-md-12 mb-lg-0 mb-4">
            <div
              class="card hoverable"
              style={{ marginBottom: "2vh", paddingBottom: "2vh" }}
            >
              <img
                class="card-img-top"
                src={apiUrl + "/" + pathCorrector(card.image)}
                alt="Card image cap"
                style={{
                  borderRadius: "2vh",
                  maxWidth: "100%",
                  minWidth: "100%",
                  maxHeight: "40vh",
                  minHeight: "40vh",
                }}
              />

              <div class="card-body">
                <a
                  href="#!"
                  class="black-text"
                  style={{
                    color: "black",
                    textDecoration: "none",
                    fontWeight: "bolder",
                    fontSize: "3vh",
                  }}
                >
                  <span className={styles.tagtilecut}>{card.title}</span>
                </a>
                <p
                  class="card-title text-muted font-small mt-3 mb-2"
                  className={styles.tagparagraf}
                >
                  {card.minitext}
                </p>
                {/* <a
                  type="button"
                  class="btn btn-flat text-white p-0 mx-0"
                  style={{
                    border: "none",
                    backgroundColor: "#fedade",
                    marginTop: "2vh",
                    width: "24vh",
                    color: "#fff",
                    fontSize: "3vh",
                  }}
                  // href="/BlogPostDetails"
                > */}
                <span
                  style={{
                    border: "none",
                    backgroundColor: "#fedade",
                    marginTop: "2vh",
                    paddingRight: "4vh",
                    paddingLeft: "4vh",
                    paddingTop: "0.2vh",
                    paddingBottom: "1vh",
                    color: "#fff",
                    fontSize: "3vh",
                    borderRadius: "2vh",
                  }}
                >
                  <Link
                    style={{
                      fontSize: "2vh",
                      textDecoration: "none",
                      color: "white",
                    }}
                    to={`BlogPostDetails/${card._id}`}
                  >
                    Read more
                    <i class="fa fa-angle-right ml-2"></i>
                  </Link>
                </span>
                {/* </a> */}
              </div>
            </div>

            {/* end blog post  */}
            {/* End model */}
          </div>
        ))}
      </div>
      <div class="text-center mt-5">
        <a
          href="/Blog"
          style={{
            fontFamily: "Roboto",
            fontSize: "3vh",
            textDecoration: "none",
            color: "white",
            backgroundColor: "#fedade",
            padding: "2vh",
            borderRadius: "4vh",
          }}
        >
          Browse all blog posts
        </a>
      </div>
    </section>
  );
}

export default LastNewsComponent;
