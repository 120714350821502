import React from "react";
import ContactUsComponent from "../components/ContactUsComponent";
import * as ReactBootStrap from "react-bootstrap";
import FooterComponent from "../components/FooterComponent";

function ContactUsScreen(props) {
  return (
    <div>
      <ReactBootStrap.Container>
        <ContactUsComponent />
        <FooterComponent />
      </ReactBootStrap.Container>
    </div>
  );
}

export default ContactUsScreen;
