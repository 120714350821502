import React, { useState, useEffect } from "react";
// import AboutUsComponent from "../components/AboutUsComponent";
import FooterComponent from "../components/FooterComponent";
import { axios } from "../util/axios";
import { apiUrl } from "../env-vars";
import styles from "./AboutUsStyle.module.css";

function AboutUsScreen(props) {
  const [cards, setCards] = useState([]);

  // Fetch data
  const fetchData = async () => {
    let response = await axios.get("about/get-all-abouts");
    let result = response.data;
    // console.log(result);
    setCards(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const pathCorrector = (path) => {
    return path.replace(/\\/g, "/");
  };

  return (
    <div>
      {cards.map((card) => (
        <div class="container py-5 my-5">
          <section
            class="p-md-1 mx-md-5 text-center text-lg-left grey z-depth-1"
            style={{
              backgroundImage:
                "url(https://mdbootstrap.com/img/Photos/Others/background2.jpg);",
            }}
          >
            <div class="row d-flex justify-content-center">
              <div class="col-md-10">
                <div class="card">
                  <div class="card-body m-3">
                    <div class="row">
                      <div class="col-lg-4 d-flex mb-2 align-items-center">
                        <div class="avatar mx-4 w-100 white d-flex justify-content-center align-items-center">
                          <img
                            src={apiUrl + "/" + pathCorrector(card.image)}
                            class="rounded-circle img-fluid z-depth-1"
                            alt="woman avatar"
                          />
                        </div>
                      </div>
                      <div class="col-lg-8">
                        <p
                          class="text-muted font-weight-light mb-4"
                          className={styles.thissss}
                        >
                          {card.description}
                        </p>
                        {/* <p class="font-weight-bold lead mb-2">
                          <strong>Anna Smith</strong>
                        </p> */}
                        <p
                          className={styles.thissss}
                          class="font-weight-bold text-muted mb-0"
                        >
                          {card.aboutdescription}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ))}
      <FooterComponent />
    </div>
  );
}

export default AboutUsScreen;
