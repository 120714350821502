import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

function CreateNewPrice({ NewNewPriceHandler }) {
  const [one, setone] = useState("");
  const [two, settwo] = useState("");
  const [tree, settree] = useState("");
  const [forr, setforr] = useState("");
  const [five, setfive] = useState("");
  const [six, setsix] = useState("");
  const [seven, setseven] = useState("");
  const [eight, seteight] = useState("");
  const [nine, setnine] = useState("");
  const [ten, setten] = useState("");
  const [oneone, setoneone] = useState("");
  const [twotwo, settwotwo] = useState("");
  const [treetree, settreetree] = useState("");
  const [forfor, setforfor] = useState("");
  const [fivefive, setfivefive] = useState("");
  const [sixsix, setsixsix] = useState("");
  const [sevenseven, setsevenseven] = useState("");
  const [eighteight, seteighteight] = useState("");
  const [ninenine, setninenine] = useState("");
  const [tenten, settenten] = useState("");

  const ononeChangeHandler = (event) => {
    setone(event.target.value);
  };
  const ontwoChangeHandler = (event) => {
    settwo(event.target.value);
  };
  const ontreeChangeHandler = (event) => {
    settree(event.target.value);
  };
  const onforrChangeHandler = (event) => {
    setforr(event.target.value);
  };
  const onfiveChangeHandler = (event) => {
    setfive(event.target.value);
  };
  const onsixChangeHandler = (event) => {
    setsix(event.target.value);
  };
  const onsevenChangeHandler = (event) => {
    setseven(event.target.value);
  };
  const oneightChangeHandler = (event) => {
    seteight(event.target.value);
  };
  const onnineChangeHandler = (event) => {
    setnine(event.target.value);
  };
  const ontenChangeHandler = (event) => {
    setten(event.target.value);
  };
  const ononeoneChangeHandler = (event) => {
    setoneone(event.target.value);
  };
  const ontwotwoChangeHandler = (event) => {
    settwotwo(event.target.value);
  };
  const ontreetreeChangeHandler = (event) => {
    settreetree(event.target.value);
  };
  const onforforChangeHandler = (event) => {
    setforfor(event.target.value);
  };
  const onfivefiveChangeHandler = (event) => {
    setfivefive(event.target.value);
  };
  const onsixsixChangeHandler = (event) => {
    setsixsix(event.target.value);
  };
  const onsevensevenChangeHandler = (event) => {
    setsevenseven(event.target.value);
  };
  const oneighteightChangeHandler = (event) => {
    seteighteight(event.target.value);
  };
  const onninenineChangeHandler = (event) => {
    setninenine(event.target.value);
  };
  const ontentenChangeHandler = (event) => {
    settenten(event.target.value);
  };

  const pathCorrector = (path) => {
    return path.replace(/\\/g, "/");
  };

  const submitForm = (e) => {
    e.preventDefault();
    // console.log(title, description);
    NewNewPriceHandler(
      one,
      two,
      tree,
      forr,
      five,
      six,
      seven,
      eight,
      nine,
      ten,
      oneone,
      twotwo,
      treetree,
      forfor,
      fivefive,
      sixsix,
      sevenseven,
      eighteight,
      ninenine,
      tenten
    );
  };

  return (
    <div>
      <Form onSubmit={(e) => submitForm(e)}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>field one</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={one}
            onChange={(event) => ononeChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field two</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={two}
            onChange={(event) => ontwoChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field tree</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={tree}
            onChange={(event) => ontreeChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field for</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={forr}
            onChange={(event) => onforrChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field five</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={five}
            onChange={(event) => onfiveChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field six</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={six}
            onChange={(event) => onsixChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field seven</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={seven}
            onChange={(event) => onsevenChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field eight</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={eight}
            onChange={(event) => oneightChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field nine</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={nine}
            onChange={(event) => onnineChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field ten</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={ten}
            onChange={(event) => ontenChangeHandler(event)}
          />
        </Form.Group>
        {/* newxx 10 */}
        <Form.Group controlId="formBasicEmail">
          <Form.Label>field 11</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={oneone}
            onChange={(event) => ononeoneChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field 12</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={twotwo}
            onChange={(event) => ontwotwoChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field 13</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={treetree}
            onChange={(event) => ontreetreeChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field 14</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={forfor}
            onChange={(event) => onforforChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field 15</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={fivefive}
            onChange={(event) => onfivefiveChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field 16</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={sixsix}
            onChange={(event) => onsixsixChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field 17</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={sevenseven}
            onChange={(event) => onsevensevenChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field 18</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={eighteight}
            onChange={(event) => oneighteightChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field 19</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={ninenine}
            onChange={(event) => onninenineChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>field 20</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter field one"
            value={tenten}
            onChange={(event) => ontentenChangeHandler(event)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default CreateNewPrice;
