import React, { useState, useEffect } from "react";
import styles from "./styles/myCardStyle.module.css";
import { Modal, Button, Alert, Card, CardGroup, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { axios } from "../util/axios";

import NewCard from "../models/NewCard";
import EditNewCard from "./EditNewCard";
import CreateNewCard from "./CreateNewCard";

function MyCardComponent({ userAuthData }) {
  //
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [isShowCreateModal, setIsShowCreateModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showCreateAlert, setshowCreateAlert] = useState(false);
  const [showEditAlert, setshowEditAlert] = useState(false);

  //

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //

  // Fetch Data from sever
  const fetchData = async () => {
    let response = await axios.get("newcard/get-all-newcards");
    let result = response.data;
    // console.log(result);
    setCards(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const showEditModalHandler = () => {
    setIsShowEditModal(true);
  };
  const closeEditModalHandler = () => {
    setIsShowEditModal(false);
    setSelectedCard(null);
  };
  const showCreateModalHandler = () => {
    setIsShowCreateModal(true);
  };
  const closeCreateModalHandler = () => {
    setIsShowCreateModal(false);
    setSelectedCard(null);
  };
  const selectCardHandler = (card) => {
    setSelectedCard(card);
    showEditModalHandler();
  };
  // new header image and titel create handler function
  const NewNewCardHandler = async (
    image,
    title,
    description,
    dettitleone,
    detdescriptionone,
    dettitletwo,
    detdescriptiontwo,
    dettitletree,
    detdescriptiontree,
    linktitle,
    linkaddress
  ) => {
    const newcard = new NewCard(
      image,
      title,
      description,
      dettitleone,
      detdescriptionone,
      dettitletwo,
      detdescriptiontwo,
      dettitletree,
      detdescriptiontree,
      linktitle,
      linkaddress
    );
    try {
      let response = await newcard.create(userAuthData.replace(/['"]+/g, ""));
      let result = response.data;
      console.log(result);
      // window.alert(result.title + " " + result.title);
      setshowCreateAlert(true);
      closeCreateModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error);
    }
  };

  const NewEditCardHandler = async (
    newcardId,
    title,
    description,
    dettitleone,
    detdescriptionone,
    dettitletwo,
    detdescriptiontwo,
    dettitletree,
    detdescriptiontree,
    linktitle,
    linkaddress,
    image
  ) => {
    const newcard = new NewCard(
      title,
      description,
      dettitleone,
      detdescriptionone,
      dettitletwo,
      detdescriptiontwo,
      dettitletree,
      detdescriptiontree,
      linktitle,
      linkaddress,
      image
    );
    try {
      let response = await newcard.edit(
        newcardId,
        userAuthData.replace(/['"]+/g, "")
      );
      let result = response.data;
      console.log(result);
      // window.alert(result.title + " " + result.description);
      setshowEditAlert(true);
      closeEditModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };
  const deleteNewCardHandler = async (newcardId) => {
    const newcard = new NewCard();
    try {
      let response = await newcard.delete(
        newcardId,
        userAuthData.replace(/['"]+/g, "")
      );
      let result = response.data;
      // window.alert(result.title + " با موفقیت حذف شد");
      closeCreateModalHandler();
      handleClose();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };
  return (
    <>
      {/* Alert Create */}
      {showCreateAlert ? (
        <div>
          <Alert
            style={{
              margin: "2vh",
            }}
            variant="success"
            onClose={() => setshowCreateAlert(false)}
            dismissible
          >
            <Alert.Heading>Your Card Created Successfully</Alert.Heading>
            <h2></h2>
          </Alert>
        </div>
      ) : null}
      {/* Alert Create */}
      {/* Alert For Delete */}
      {cards.map((card) => (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete {card.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo,Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => deleteNewCardHandler(card._id)}
            >
              Yes Im Sure
            </Button>
          </Modal.Footer>
        </Modal>
      ))}
      {/* Alert For Delete */}
      {/* Alert Edited */}
      {showEditAlert ? (
        <div>
          <Alert
            style={{
              margin: "2vh",
            }}
            variant="success"
            onClose={() => setshowEditAlert(false)}
            dismissible
          >
            <Alert.Heading>Your Card Edited Successfully</Alert.Heading>
            <h2></h2>
          </Alert>
        </div>
      ) : null}
      {/* Alert Edited */}
      {userAuthData ? (
        <center style={{ margin: "4vhs" }}>
          <Button
            onClick={showCreateModalHandler}
            style={{
              position: "relative",
              top: "1vh",
              margin: "2vh",
            }}
            variant="success"
          >
            Create New card here
          </Button>
          <hr></hr>
        </center>
      ) : null}
      {/* <h1>{userAuthData.replace(/['"]+/g, "")}</h1> */}
      {/* window.alert(userAuthData.replace(/['"]+/g, "")); */}
      <div>
        <CardGroup>
          {/* Modal Part Start */}

          <Modal
            show={isShowCreateModal}
            onHide={closeCreateModalHandler}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {selectedCard ? selectedCard.title : ""}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* EditCard */}
              <CreateNewCard NewNewCardHandler={NewNewCardHandler} />
              {/* EditCard */}
            </Modal.Body>
          </Modal>

          {/* Modal Part End */}
          {/* Modal Part Start */}
          {cards.map((card) => (
            <Modal
              show={isShowEditModal}
              onHide={closeEditModalHandler}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Body>
                {/* EditCard */}
                <EditNewCard
                  NewEditCardHandler={NewEditCardHandler}
                  deleteNewCardHandler={deleteNewCardHandler}
                  selectedCard={selectedCard}
                />
                {/* EditCard */}
              </Modal.Body>
            </Modal>
          ))}
          {/* Modal Part End */}
          {/* Start Card frist */}

          {/* start second Card */}
          {cards.map((card) => (
            <Col xs={16} md={3}>
              <Card style={{ border: "none" }}>
                {userAuthData ? (
                  <center style={{ margin: "4vhs" }}>
                    <Button
                      variant="warning"
                      onClick={() => selectCardHandler(card)}
                      style={{
                        // backgroundColor: "#F6A9A9",
                        paddingLeft: "20%",
                        paddingRight: "20%",
                        paddingTop: "1vh",
                        paddingBottom: "1vh",
                        borderRadius: "4vh",
                        cursor: "pointer",
                        position: "relative",
                        top: "0.5vh",
                      }}
                    >
                      EDIT
                    </Button>
                  </center>
                ) : null}
                {userAuthData ? (
                  <Button
                    onClick={handleShow}
                    style={{
                      paddingLeft: "10%",
                      paddingRight: "10%",
                      paddingTop: "1vh",
                      paddingBottom: "1vh",
                      borderRadius: "4vh",
                      cursor: "pointer",
                      position: "relative",
                      top: "1vh",
                      margin: "1vh",
                    }}
                    variant="danger"
                  >
                    delete it
                  </Button>
                ) : null}
                {/*  */}
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/CardPostDetails/${card._id}`}
                >
                  <Card.Body className={styles.card}>
                    <Card.Title className={styles.tagtitle}>
                      {card.title}
                    </Card.Title>
                    <Card.Text className={styles.tagparagraf}>
                      {card.description}
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
            </Col>
          ))}
        </CardGroup>
      </div>
    </>
  );
}

export default MyCardComponent;
