import React, { useState } from "react";
import { Form, Button, Image } from "react-bootstrap";

function CreateHeader({ NewHeaderHandler }) {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);

  const onTitleChangeHandler = (event) => {
    setTitle(event.target.value);
  };
  const onImageChangeHandler = (event) => {
    setImage(event.target.files[0]);
  };

  const pathCorrector = (path) => {
    return path.replace(/\\/g, "/");
  };

  const submitForm = (e) => {
    e.preventDefault();
    // console.log(title, description);
    NewHeaderHandler(title, image);
  };

  return (
    <div>
      <Form onSubmit={(e) => submitForm(e)}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>title</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter decription"
            value={title}
            onChange={(event) => onTitleChangeHandler(event)}
          />
        </Form.Group>
        {/* image */}
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>file input </Form.Label>
          <Form.Control
            type="file"
            onChange={(event) => onImageChangeHandler(event)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default CreateHeader;
