import { axios } from "../util/axios";

class NewPrice {
  constructor(
    one,
    two,
    tree,
    forr,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    oneone,
    twotwo,
    treetree,
    forfor,
    fivefive,
    sixsix,
    sevenseven,
    eighteight,
    ninenine,
    tenten
  ) {
    this.one = one;
    this.two = two;
    this.tree = tree;
    this.forr = forr;
    this.five = five;
    this.six = six;
    this.seven = seven;
    this.eight = eight;
    this.nine = nine;
    this.ten = ten;
    this.oneone = oneone;
    this.twotwo = twotwo;
    this.treetree = treetree;
    this.forfor = forfor;
    this.fivefive = fivefive;
    this.sixsix = sixsix;
    this.sevenseven = sevenseven;
    this.eighteight = eighteight;
    this.ninenine = ninenine;
    this.tenten = tenten;
  }
  create = (token) => {
    const fd = new FormData();
    fd.append("one", this.one);
    fd.append("two", this.two);
    fd.append("tree", this.tree);
    fd.append("forr", this.forr);
    fd.append("five", this.five);
    fd.append("six", this.six);
    fd.append("seven", this.seven);
    fd.append("eight", this.eight);
    fd.append("nine", this.nine);
    fd.append("ten", this.ten);
    fd.append("oneone", this.oneone);
    fd.append("twotwo", this.twotwo);
    fd.append("treetree", this.treetree);
    fd.append("forfor", this.forfor);
    fd.append("fivefive", this.fivefive);
    fd.append("sixsix", this.sixsix);
    fd.append("sevenseven", this.sevenseven);
    fd.append("eighteight", this.eighteight);
    fd.append("ninenine", this.ninenine);
    fd.append("tenten", this.tenten);

    return axios.post(
      "newprice/create-newprice",
      // {
      //   name: this.name,
      //   price: this.price,
      //   isAvail: this.isAvail,
      // },
      fd,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  edit = (newpriceId, token) => {
    const fd = new FormData();
    fd.append("one", this.one);
    fd.append("two", this.two);
    fd.append("tree", this.tree);
    fd.append("forr", this.forr);
    fd.append("five", this.five);
    fd.append("six", this.six);
    fd.append("seven", this.seven);
    fd.append("eight", this.eight);
    fd.append("nine", this.nine);
    fd.append("ten", this.ten);
    fd.append("oneone", this.oneone);
    fd.append("twotwo", this.twotwo);
    fd.append("treetree", this.treetree);
    fd.append("forfor", this.forfor);
    fd.append("fivefive", this.fivefive);
    fd.append("sixsix", this.sixsix);
    fd.append("sevenseven", this.sevenseven);
    fd.append("eighteight", this.eighteight);
    fd.append("ninenine", this.ninenine);
    fd.append("tenten", this.tenten);

    fd.append("image", this.image);

    return axios.patch(
      `newprice/edit-newprice/${newpriceId}`,
      // {
      //   price: this.price,
      //   isAvail: this.isAvail,
      // },
      fd,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  delete = (newpriceId, token) => {
    return axios.delete(`newprice/delete-newprice/${newpriceId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  };
}

export default NewPrice;
