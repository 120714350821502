import React from "react";

function NomatchScreen(props) {
  return (
    <center style={{ margin: "20vh" }}>
      <h4>404 NOT FOUND</h4>
    </center>
  );
}

export default NomatchScreen;
