import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

function EditNewCard({
  deleteNewCardHandler,
  selectedCard,
  NewEditCardHandler,
}) {
  const [title, settitle] = useState(selectedCard ? selectedCard.title : "");
  const [description, setdescription] = useState(
    selectedCard ? selectedCard.description : ""
  );

  const [titleone, settitleone] = useState(
    selectedCard ? selectedCard.titleone : ""
  );
  const [descriptionone, setdescriptionone] = useState(
    selectedCard ? selectedCard.descriptionone : ""
  );

  const [titletwo, settitletwo] = useState(
    selectedCard ? selectedCard.titletwo : ""
  );
  const [descriptiontwo, setdescriptiontwo] = useState(
    selectedCard ? selectedCard.descriptiontwo : ""
  );

  const [titletree, settitletree] = useState(
    selectedCard ? selectedCard.titletree : ""
  );
  const [descriptiontree, setdescriptiontree] = useState(
    selectedCard ? selectedCard.descriptiontree : ""
  );

  const [linkaddress, setlinkaddress] = useState(
    selectedCard ? selectedCard.linkaddress : ""
  );
  const [linkname, setlinkname] = useState(
    selectedCard ? selectedCard.linkname : ""
  );

  const [image, setimage] = useState(null);

  const ontitleChangeHandler = (event) => {
    settitle(event.target.value);
  };

  const ondescriptionChangeHandler = (event) => {
    setdescription(event.target.value);
  };

  const ontitleoneChangeHandler = (event) => {
    settitleone(event.target.value);
  };

  const ondescriptiononeChangeHandler = (event) => {
    setdescriptionone(event.target.value);
  };
  const ontitletwoChangeHandler = (event) => {
    settitletwo(event.target.value);
  };

  const ondescriptiontwoChangeHandler = (event) => {
    setdescriptiontwo(event.target.value);
  };
  const ontitletreeChangeHandler = (event) => {
    settitletree(event.target.value);
  };

  const ondescriptiontreeChangeHandler = (event) => {
    setdescriptiontree(event.target.value);
  };
  const onlinkaddressChangeHandler = (event) => {
    setlinkaddress(event.target.value);
  };

  const onlinknameChangeHandler = (event) => {
    setlinkname(event.target.value);
  };

  const onimageChangeHandler = (event) => {
    setimage(event.target.files[0]);
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log(
      selectedCard._id,
      title,
      description,
      titleone,
      descriptionone,
      titletwo,
      descriptiontwo,
      titletree,
      descriptiontree,
      linkaddress,
      linkname
    );
    NewEditCardHandler(
      selectedCard._id,
      title,
      description,
      titleone,
      descriptionone,
      titletwo,
      descriptiontwo,
      titletree,
      descriptiontree,
      linkaddress,
      linkname,
      image
    );
    // deletePostblogHandler(selectedCard._id);
  };

  const deletehandlerbutton = (e) => {
    e.preventDefault();
    deleteNewCardHandler(selectedCard._id);
  };

  return (
    <div>
      {selectedCard && (
        <Form onSubmit={(e) => submitForm(e)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>title</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter title"
              value={title}
              onChange={(event) => ontitleChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>decription</Form.Label>
            <Form.Control
              type="description"
              as="textarea"
              rows={10}
              placeholder="Enter decription"
              value={description}
              onChange={(event) => ondescriptionChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>title one</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter title"
              value={titleone}
              onChange={(event) => ontitleoneChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>decription one</Form.Label>
            <Form.Control
              type="description"
              as="textarea"
              rows={10}
              placeholder="Enter decription"
              value={descriptionone}
              onChange={(event) => ondescriptiononeChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>title two</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter title"
              value={titletwo}
              onChange={(event) => ontitletwoChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>decription two</Form.Label>
            <Form.Control
              type="description"
              as="textarea"
              rows={10}
              placeholder="Enter decription"
              value={descriptiontwo}
              onChange={(event) => ondescriptiontwoChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>title tree</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter title"
              value={titletree}
              onChange={(event) => ontitletreeChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>decription tree</Form.Label>
            <Form.Control
              type="description"
              as="textarea"
              rows={10}
              placeholder="Enter decription"
              value={descriptiontree}
              onChange={(event) => ondescriptiontreeChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Link Adress</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter Link Adress"
              value={linkaddress}
              onChange={(event) => onlinkaddressChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Link Name</Form.Label>
            <Form.Control
              type="description"
              as="textarea"
              rows={1}
              placeholder="Enter decription"
              value={linkname}
              onChange={(event) => onlinknameChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group
            onChange={(event) => onimageChangeHandler(event)}
            controlId="formFileSm"
            className="mb-3"
          >
            <Form.Label>Small file input example</Form.Label>
            <Form.Control type="file" size="sm" />
          </Form.Group>
          <Button variant="primary" type="submit" style={{ margin: "2vh" }}>
            Submit
          </Button>
          <Button onClick={deletehandlerbutton} variant="danger">
            Delete this post
          </Button>
        </Form>
      )}
    </div>
  );
}

export default EditNewCard;
