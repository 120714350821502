import { axios } from "../util/axios";

class BlogPost {
  constructor(title, minitext, description, image) {
    this.title = title;
    this.minitext = minitext;
    this.description = description;
    this.image = image;
  }
  create = (token) => {
    const fd = new FormData();
    fd.append("title", this.title);
    fd.append("minitext", this.minitext);
    fd.append("description", this.description);
    fd.append("image", this.image);

    return axios.post(
      "blog/create-blogpost",
      // {
      //   name: this.name,
      //   price: this.price,
      //   isAvail: this.isAvail,
      // },
      fd,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  edit = (blogpostId, token) => {
    const fd = new FormData();
    fd.append("title", this.title);
    fd.append("minitext", this.minitext);
    fd.append("description", this.description);
    fd.append("image", this.image);

    return axios.patch(
      `blog/edit-blogpost/${blogpostId}`,
      // {
      //   price: this.price,
      //   isAvail: this.isAvail,
      // },
      fd,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  delete = (blogpostId, token) => {
    return axios.delete(`blog/delete-blogpost/${blogpostId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  };
}

export default BlogPost;
