import React from "react";
import FooterComponent from "../components/FooterComponent";
import ServiceAndPriceComponent from "../components/ServiceAndPriceComponent";
import { Container } from "react-bootstrap";

function ServiseAndPriceScreen(props) {
  return (
    <Container>
      <ServiceAndPriceComponent />
      <FooterComponent />
    </Container>
  );
}

export default ServiseAndPriceScreen;
