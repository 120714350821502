import React, { useState, useEffect } from "react";
import { Modal, Button, Alert } from "react-bootstrap";

import styles from "./styles/OurCustomerStyle.module.css";
import { axios } from "../util/axios";
import { apiUrl } from "../env-vars";
import Customer from "../models/Customer";
import CraeteCustomer from "./CreateCustomer";
import EditCustomer from "./EditCustomer";
// const userAuthData = localStorage.getItem("userAuthData", "userId");
function OurCustomerComponent({ userAuthData }) {
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [isShowLoginForm, setIsShowLoginForm] = useState(false);
  const [showEditAlert, setshowEditAlert] = useState(false);

  // Fetch Data from sever
  const fetchData = async () => {
    let response = await axios.get("customer/get-all-customers");
    let result = response.data;
    // console.log(result);
    setCards(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const showEditModalHandler = () => {
    setIsShowEditModal(true);
  };
  const closeEditModalHandler = () => {
    setIsShowEditModal(false);
    setSelectedCard(null);
  };
  const selectCardHandler = (card) => {
    setSelectedCard(card);
    showEditModalHandler();
  };

  const showModalHandler = () => {
    setIsShowLoginForm(true);
  };

  const closeModalHandler = () => {
    setIsShowLoginForm(false);
  };
  const pathCorrector = (path) => {
    return path.replace(/\\/g, "/");
  };

  const NewCustomerHandler = async (title, boldtitle, description, image) => {
    const product = new Customer(title, boldtitle, description, image);
    try {
      let response = await product.create(userAuthData.replace(/['"]+/g, ""));
      let result = response.data;
      console.log(result);
      window.alert(result.title + " " + result.boldtitle);
      closeEditModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };

  const NeweditCustomerHandler = async (
    customerId,
    title,
    boldtitle,
    description,
    image
  ) => {
    const mycard = new Customer(title, boldtitle, description, image);
    try {
      let response = await mycard.edit(
        customerId,
        userAuthData.replace(/['"]+/g, "")
      );
      let result = response.data;
      console.log(result);
      // window.alert(result.title + " " + result.description);
      setshowEditAlert(true);
      closeEditModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };
  return (
    <>
      {/* Alert Edited */}
      {showEditAlert ? (
        <div>
          <Alert
            style={{
              margin: "2vh",
            }}
            variant="success"
            onClose={() => setshowEditAlert(false)}
            dismissible
          >
            <Alert.Heading>Our Customer Edited Successfully</Alert.Heading>
          </Alert>
        </div>
      ) : null}
      {/* Alert Edited */}
      {/* Modal Part Start */}

      <Modal show={isShowLoginForm} onHide={closeModalHandler} className="rtl">
        <Modal.Header closeButton>
          <Modal.Title>new Customer form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CraeteCustomer NewCustomerHandler={NewCustomerHandler} />
        </Modal.Body>
      </Modal>

      {/* Modal Part End */}

      {/* Modal Part Start */}

      <Modal
        show={isShowEditModal}
        onHide={closeEditModalHandler}
        className="rtl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedCard ? selectedCard.title : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditCustomer
            NeweditCustomerHandler={NeweditCustomerHandler}
            selectedCard={selectedCard}
          />
        </Modal.Body>
      </Modal>

      {/* Modal Part End */}
      {userAuthData ? (
        <center style={{ margin: "4vhs" }}>
          {/* <Button
            variant="success"
            onClick={showModalHandler}
            style={{
              // backgroundColor: "#F6A9A9",
              paddingLeft: "20%",
              paddingRight: "20%",
              paddingTop: "1vh",
              paddingBottom: "1vh",
              borderRadius: "4vh",
              cursor: "pointer",
            }}
          >
            New Our customer create
          </Button> */}
        </center>
      ) : null}
      <div className={styles.mainContainer}>
        {cards.map((card) => (
          <div className="row row-50 align-items-lg-center justify-content-xl-between">
            <div className="col-lg-6">
              <div className="block-xs">
                {/* Edit Admin Button For card */}
                {userAuthData ? (
                  <center style={{ margin: "4vhs" }}>
                    <Button
                      onClick={() => selectCardHandler(card)}
                      variant="warning"
                      style={{
                        // backgroundColor: "#F6A9A9",
                        paddingLeft: "20%",
                        paddingRight: "20%",
                        paddingTop: "1vh",
                        paddingBottom: "1vh",
                        borderRadius: "4vh",
                        cursor: "pointer",
                      }}
                    >
                      EDIT Our Client
                    </Button>
                    {/* <a onClick={showModalHandler}>create customer</a> */}
                  </center>
                ) : null}

                {/* End Edit Admin button For card */}
                <h2 className={styles.title}>
                  {card.title} <br />
                  <span></span>
                </h2>
                <p className={styles.paragrafOne}>{card.boldtitle}</p>
                <p>{card.description}</p>
                <a className={styles.button} href="/about">
                  read more
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              {card.image ? (
                <img
                  className="img-fluid shadow-4"
                  src={apiUrl + "/" + pathCorrector(card.image)}
                  alt=""
                  width="500vh"
                  height="400vh"
                  id={styles.image}
                />
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default OurCustomerComponent;
