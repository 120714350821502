import React, { useState } from "react";
import { Form, Button, Image } from "react-bootstrap";

function CreateAbout({ NewAboutHandler }) {
  const [aboutdescription, setaboutdescription] = useState("");
  const [image, setImage] = useState(null);

  const ondaboutdescriptionChangeHandler = (event) => {
    setaboutdescription(event.target.value);
  };
  const onImageChangeHandler = (event) => {
    setImage(event.target.files[0]);
  };

  const pathCorrector = (path) => {
    return path.replace(/\\/g, "/");
  };

  const submitForm = (e) => {
    e.preventDefault();
    // console.log(title, description);
    NewAboutHandler(aboutdescription, image);
  };

  return (
    <div>
      <Form onSubmit={(e) => submitForm(e)}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>decription</Form.Label>
          <Form.Control
            type="aboutdescription"
            placeholder="Enter decription"
            value={aboutdescription}
            onChange={(event) => ondaboutdescriptionChangeHandler(event)}
          />
        </Form.Group>
        {/* image */}
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Default file input example</Form.Label>
          <Form.Control
            type="file"
            onChange={(event) => onImageChangeHandler(event)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default CreateAbout;
