import React, { useState, useEffect } from "react";
import { Container, Alert, Button, Modal } from "react-bootstrap";
import Payment from "../models/Payment";
import CreatePayment from "./CreatePaymnet";
import { axios } from "../util/axios";
import FooterComponent from "../components/FooterComponent";

function PaymentScreen(props) {
  const userAuthData = localStorage.getItem("userAuthData", "userId");
  const [isShowLoginForm, setIsShowLoginForm] = useState(false);
  const [cards, setCards] = useState([]);

  // Fetch Data from sever
  const fetchData = async () => {
    let response = await axios.get("payment/get-all-payments");
    let result = response.data;
    // console.log(result);
    setCards(result);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const showModalHandler = () => {
    setIsShowLoginForm(true);
  };

  const closeModalHandler = () => {
    setIsShowLoginForm(false);
  };

  const NewpaymentHandler = async (titlepayment, description) => {
    const ppayment = new Payment(titlepayment, description);
    console.log(ppayment);
    try {
      let response = await ppayment.create(userAuthData.replace(/['"]+/g, ""));
      let result = response.data;
      window.alert(result.description + " " + result.description);
      closeModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };
  const deletePaymentHandler = async (paymentId) => {
    const payment = new Payment();
    try {
      let response = await payment.delete(
        paymentId,
        userAuthData.replace(/['"]+/g, "")
      );
      let result = response.data;
      window.alert(result.titlepayment + " با موفقیت حذف شد");
      closeModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };
  return (
    <Container>
      <div>
        {userAuthData ? (
          <center style={{ margin: "4vhs" }}>
            <Button
              onClick={showModalHandler}
              style={{
                position: "relative",
                top: "1vh",
                margin: "2vh",
              }}
              variant="success"
            >
              Create New Role
            </Button>
          </center>
        ) : null}
        <hr></hr>
        {/* Modal Part Start */}

        <Modal
          show={isShowLoginForm}
          onHide={closeModalHandler}
          className="rtl"
        >
          <Modal.Header closeButton>
            <Modal.Title>new role line</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreatePayment NewpaymentHandler={NewpaymentHandler} />
          </Modal.Body>
        </Modal>

        {/* Modal Part End */}
      </div>
      <div style={{ margin: "3vh" }}>
        <Alert variant="success">
          <Alert.Heading>Payment</Alert.Heading>
        </Alert>
        {cards.map((card) => (
          <Alert
            style={{ fontWeight: "700", marginTop: "2vh" }}
            variant="success"
          >
            <Alert.Heading style={{ fontWeight: "bolder" }}>
              {card.titlepayment}
            </Alert.Heading>
            _{card.description}
            <br />
            {userAuthData ? (
              <Button
                style={{
                  paddingLeft: "5vh",
                  paddingRight: "5vh",
                  position: "absolute",
                  zIndex: "1",
                }}
                onClick={() => deletePaymentHandler(card._id)}
                variant="danger"
              >
                delete
              </Button>
            ) : null}
          </Alert>
        ))}
      </div>
      <FooterComponent />
    </Container>
  );
}

export default PaymentScreen;
