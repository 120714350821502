import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import * as ReactBootStrap from "react-bootstrap";
import styles from "./styles/navbarStyle.module.css";
import { axios } from "../util/axios";
import Logo from "../assets/img/LogoNew.png";
import LogoW from "../assets/img/Logo-White.png";

function NavbarComponent(props) {
  const [cards, setCards] = useState([]);
  // Fetch Data from sever
  const fetchData = async () => {
    let response = await axios.get("newcard/get-all-newcards");
    let result = response.data;
    // console.log(result);
    setCards(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="App">
      <ReactBootStrap.Container>
        <ReactBootStrap.Navbar
          collapseOnSelect
          expand="md"
          className={styles.navbarCustom}
        >
          <ReactBootStrap.Navbar.Brand href="/" className={styles.navTitle}>
            <ReactBootStrap.Image
              src={Logo}
              style={{
                width: "10vh",
                height: "6vh",
              }}
            />
          </ReactBootStrap.Navbar.Brand>
          <ReactBootStrap.Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            style={{ color: "white" }}
          />
          <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
            <ReactBootStrap.Nav className="mr-auto">
              <Link to="/" className={styles.linkstyle}>
                <ReactBootStrap.Nav.Link
                  style={{ fontWeight: "bold" }}
                  href="/"
                >
                  Home
                </ReactBootStrap.Nav.Link>
              </Link>
              <Link
                to="/about"
                className={styles.linkstyle}
                style={{ fontWeight: "bold" }}
              >
                <ReactBootStrap.Nav.Link href="#About">
                  About us
                </ReactBootStrap.Nav.Link>
              </Link>

              <ReactBootStrap.NavDropdown
                style={{ fontWeight: "bold" }}
                title="Services"
                id="collasible-nav-dropdown"
              >
                {cards.map((card) => (
                  <a
                    onClick={() => {
                      window.location.href = `/CardPostDetails/${card._id}`;
                    }}
                  >
                    <ReactBootStrap.NavDropdown.Item>
                      {/* <Link
                      style={{ textDecoration: "none" }}
                      to={`/CardPostDetails/${card._id}`}
                    > */}

                      {card.title}
                      {/* </Link> */}
                    </ReactBootStrap.NavDropdown.Item>
                  </a>
                ))}
              </ReactBootStrap.NavDropdown>
              {/*  */}

              {/* Another Nav dopn */}
              <ReactBootStrap.NavDropdown
                title="Prices"
                id="collasible-nav-dropdown"
                style={{ fontWeight: "bold" }}
              >
                <Link to="/services" className={styles.linkstyle}>
                  <ReactBootStrap.NavDropdown.Item href="#action/3.1">
                    Prices
                  </ReactBootStrap.NavDropdown.Item>
                </Link>
                <ReactBootStrap.NavDropdown.Item href="/payment">
                  Payment
                </ReactBootStrap.NavDropdown.Item>
                <ReactBootStrap.NavDropdown.Item href="/cancellation">
                  Cancellation
                </ReactBootStrap.NavDropdown.Item>
                <ReactBootStrap.NavDropdown.Divider />
              </ReactBootStrap.NavDropdown>
              <Link to="/contact" className={styles.linkstyle}>
                <ReactBootStrap.Nav.Link
                  href="#Contact"
                  style={{ fontWeight: "bold" }}
                >
                  Contact Us
                </ReactBootStrap.Nav.Link>
              </Link>
              {/* ENd */}
            </ReactBootStrap.Nav>
          </ReactBootStrap.Navbar.Collapse>
        </ReactBootStrap.Navbar>
      </ReactBootStrap.Container>
    </div>
  );
}

export default NavbarComponent;
