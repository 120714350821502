import { axios } from "../util/axios";

class Payment {
  constructor(titlepayment, description) {
    this.titlepayment = titlepayment;
    this.description = description;
  }
  create = (token) => {
    const fd = new FormData();
    fd.append("titlepayment", this.titlepayment);
    fd.append("description", this.description);

    return axios.post(
      "payment/create-payment",
      // {
      //   name: this.name,
      //   price: this.price,
      //   isAvail: this.isAvail,
      // },
      fd,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  edit = (paymentId, token) => {
    const fd = new FormData();
    fd.append("titlepayment", this.titlepayment);
    fd.append("description", this.description);

    return axios.patch(
      `payment/edit-payment/${paymentId}`,
      // {
      //   price: this.price,
      //   isAvail: this.isAvail,
      // },
      fd,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  delete = (paymentId, token) => {
    return axios.delete(`payment/delete-payment/${paymentId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  };
}

export default Payment;
