import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

function EditCard({ NeweditCustomerHandler, selectedCard }) {
  const [description, setdescription] = useState(
    selectedCard ? selectedCard.description : ""
  );
  const [number, setnumber] = useState(selectedCard ? selectedCard.number : "");
  const [address, setaddress] = useState(
    selectedCard ? selectedCard.address : ""
  );
  const [email, setemail] = useState(selectedCard ? selectedCard.email : "");

  const ondescriptionChangeHandler = (event) => {
    setdescription(event.target.value);
  };
  const onnumberChangeHandler = (event) => {
    setnumber(event.target.value);
  };
  const onaddressChangeHandler = (event) => {
    setaddress(event.target.value);
  };
  const onemailChangeHandler = (event) => {
    setemail(event.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();
    // console.log(selectedCard._id, description, address, number, email);
    NeweditCustomerHandler(
      selectedCard._id,
      description,
      address,
      number,
      email
    );
  };

  return (
    <div>
      {selectedCard && (
        <Form onSubmit={(e) => submitForm(e)}>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>description</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              maxLength={1300}
              type="description"
              placeholder="Enter description"
              value={description}
              onChange={(event) => ondescriptionChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>address</Form.Label>
            <Form.Control
              type="address"
              maxLength={60}
              placeholder="Enter address"
              value={address}
              onChange={(event) => onaddressChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>number</Form.Label>
            <Form.Control
              type="string"
              maxLength={30}
              placeholder="Enter number"
              value={number}
              onChange={(event) => onnumberChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>email</Form.Label>
            <Form.Control
              type="email"
              maxLength={40}
              placeholder="Enter email"
              value={email}
              onChange={(event) => onemailChangeHandler(event)}
            />
          </Form.Group>

          <Button
            style={{
              paddingLeft: "4vh",
              paddingRight: "4vh",
              margin: "1vh",
              marginTop: "2vh",
            }}
            variant="success"
            type="submit"
          >
            Submit
          </Button>
        </Form>
      )}
    </div>
  );
}

export default EditCard;
