import React, { useState } from "react";
import { Form, Button, Image } from "react-bootstrap";

function EditCustomer({ NeweditCustomerHandler, selectedCard }) {
  const [title, settitle] = useState(selectedCard ? selectedCard.title : "");
  const [boldtitle, setboldtitle] = useState(
    selectedCard ? selectedCard.boldtitle : ""
  );
  const [description, setdescription] = useState(
    selectedCard ? selectedCard.description : ""
  );
  const [image, setImage] = useState(null);

  const ontitleChangeHandler = (event) => {
    settitle(event.target.value);
  };
  const onboldtitleChangeHandler = (event) => {
    setboldtitle(event.target.value);
  };

  const ondescriptionChangeHandler = (event) => {
    setdescription(event.target.value);
  };
  const onimageChangeHandler = (event) => {
    setImage(event.target.files[0]);
    // console.log(event.target.files[0]);
  };
  const submitForm = (e) => {
    e.preventDefault();
    console.log(selectedCard._id, image);
    NeweditCustomerHandler(
      selectedCard._id,
      title,
      boldtitle,
      description,
      image
    );
  };

  return (
    <div>
      {selectedCard && (
        <Form onSubmit={(e) => submitForm(e)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>title</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter title"
              maxLength={28}
              value={title}
              onChange={(event) => ontitleChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Bold Title</Form.Label>
            <Form.Control
              rows={5}
              as="textarea"
              maxLength={1020}
              type="boldtitle"
              placeholder="Enter Bold Title"
              value={boldtitle}
              onChange={(event) => onboldtitleChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>decription</Form.Label>
            <Form.Control
              type="description"
              rows={8}
              maxLength={2120}
              as="textarea"
              placeholder="Enter decription"
              value={description}
              onChange={(event) => ondescriptionChangeHandler(event)}
            />
          </Form.Group>
          {/* image */}
          <Form.Group
            onChange={(event) => onimageChangeHandler(event)}
            controlId="formFileSm"
            className="mb-3"
          >
            <Form.Label>Small file input example</Form.Label>
            <Form.Control type="file" size="sm" />
          </Form.Group>
          <Image src={image && URL.createObjectURL(image)} thumbnail />
          <br></br>
          <Button
            style={{
              paddingLeft: "4vh",
              paddingRight: "4vh",
              margin: "1vh",
            }}
            variant="success"
            type="submit"
          >
            Submit
          </Button>
        </Form>
      )}
    </div>
  );
}

export default EditCustomer;
