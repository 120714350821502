import { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";

export default function Login({ loginHandler }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onUsernameChangeHandler = (event) => {
    //  console.log(event.target.value);
    setUsername(event.target.value);
  };

  const onPasswordChangeHandler = (event) => {
    //  console.log(event.target.value);
    setPassword(event.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();
    // console.log({ username, password });
    loginHandler(username, password);
  };

  return (
    <div>
      <Container>
        <Form onSubmit={(e) => submitForm(e)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="username"
              placeholder="Enter username"
              value={username}
              onChange={(event) => onUsernameChangeHandler(event)}
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(event) => onPasswordChangeHandler(event)}
            />
          </Form.Group>
          <Button
            style={{
              paddingLeft: "8vh",
              paddingRight: "8vh",
              marginTop: "5vh",
            }}
            variant="success"
            type="submit"
            variant="success"
            type="submit"
          >
            LOGIN
          </Button>
        </Form>
      </Container>
    </div>
  );
}
