import { axios } from "../util/axios";

class Footer {
  constructor(number, facebook, instagram, twitter, map) {
    this.number = number;
    this.facebook = facebook;
    this.instagram = instagram;
    this.twitter = twitter;
    this.map = map;
  }

  edit = (footerId, token) => {
    const fd = new FormData();

    fd.append("number", this.number);
    fd.append("facebook", this.facebook);
    fd.append("instagram", this.instagram);
    fd.append("twitter", this.twitter);
    fd.append("map", this.map);

    return axios.patch(
      `footer/edit-footer/${footerId}`,
      {
        number: this.number,
        facebook: this.facebook,
        instagram: this.instagram,
        twitter: this.twitter,
        map: this.map,
      },

      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
}

export default Footer;
