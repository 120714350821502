import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

function EditNewPrice({
  deleteNewPriceHandler,
  selectedCard,
  NewEditPriceHandler,
}) {
  const [one, setone] = useState(selectedCard ? selectedCard.one : "");
  const [two, settwo] = useState(selectedCard ? selectedCard.two : "");
  const [tree, settree] = useState(selectedCard ? selectedCard.tree : "");
  const [forr, setforr] = useState(selectedCard ? selectedCard.forr : "");
  const [five, setfive] = useState(selectedCard ? selectedCard.five : "");
  const [six, setsix] = useState(selectedCard ? selectedCard.six : "");
  const [seven, setseven] = useState(selectedCard ? selectedCard.seven : "");
  const [eight, seteight] = useState(selectedCard ? selectedCard.eight : "");
  const [nine, setnine] = useState(selectedCard ? selectedCard.nine : "");
  const [ten, setten] = useState(selectedCard ? selectedCard.nine : "");
  const [oneone, setoneone] = useState(selectedCard ? selectedCard.oneone : "");
  const [twotwo, settwotwo] = useState(selectedCard ? selectedCard.twotwo : "");
  const [treetree, settreetree] = useState(
    selectedCard ? selectedCard.treetree : ""
  );
  const [forfor, setforfor] = useState(selectedCard ? selectedCard.forfor : "");
  const [fivefive, setfivefive] = useState(
    selectedCard ? selectedCard.fivefive : ""
  );
  const [sixsix, setsixsix] = useState(selectedCard ? selectedCard.sixsix : "");
  const [sevenseven, setsevenseven] = useState(
    selectedCard ? selectedCard.sevenseven : ""
  );
  const [eighteight, seteighteight] = useState(
    selectedCard ? selectedCard.eighteight : ""
  );
  const [ninenine, setninenine] = useState(
    selectedCard ? selectedCard.ninenine : ""
  );
  const [tenten, settenten] = useState(selectedCard ? selectedCard.tenten : "");

  const ononeChangeHandler = (event) => {
    setone(event.target.value);
  };
  const ontwoChangeHandler = (event) => {
    settwo(event.target.value);
  };
  const ontreeChangeHandler = (event) => {
    settree(event.target.value);
  };
  const onforrChangeHandler = (event) => {
    setforr(event.target.value);
  };
  const onfiveChangeHandler = (event) => {
    setfive(event.target.value);
  };
  const onsixChangeHandler = (event) => {
    setsix(event.target.value);
  };
  const onsevenChangeHandler = (event) => {
    setseven(event.target.value);
  };
  const oneightChangeHandler = (event) => {
    seteight(event.target.value);
  };
  const onnineChangeHandler = (event) => {
    setnine(event.target.value);
  };
  const ontenChangeHandler = (event) => {
    setten(event.target.value);
  };
  //   next 10
  const ononeoneChangeHandler = (event) => {
    setoneone(event.target.value);
  };
  const ontwotwoChangeHandler = (event) => {
    settwotwo(event.target.value);
  };
  const ontreetreeChangeHandler = (event) => {
    settreetree(event.target.value);
  };
  const onforforChangeHandler = (event) => {
    setforfor(event.target.value);
  };
  const onfivefiveChangeHandler = (event) => {
    setfivefive(event.target.value);
  };
  const onsixsixChangeHandler = (event) => {
    setsixsix(event.target.value);
  };
  const onsevensevenChangeHandler = (event) => {
    setsevenseven(event.target.value);
  };
  const oneigheightChangeHandler = (event) => {
    seteighteight(event.target.value);
  };
  const onninenineChangeHandler = (event) => {
    setninenine(event.target.value);
  };
  const ontentenChangeHandler = (event) => {
    settenten(event.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();
    // console.log(
    //   selectedCard._id,
    //   one,
    //   two,
    //   tree,
    //   forr,
    //   five,
    //   six,
    //   seven,
    //   eight,
    //   nine,
    //   ten,
    //   oneone,
    //   twotwo,
    //   treetree,
    //   forfor,
    //   fivefive,
    //   sixsix,
    //   sevenseven,
    //   eighteight,
    //   ninenine,
    //   tenten
    // );
    NewEditPriceHandler(
      selectedCard._id,
      one,
      two,
      tree,
      forr,
      five,
      six,
      seven,
      eight,
      nine,
      ten,
      oneone,
      twotwo,
      treetree,
      forfor,
      fivefive,
      sixsix,
      sevenseven,
      eighteight,
      ninenine,
      tenten
    );
    // deletePostblogHandler(selectedCard._id);
  };

  const deletehandlerbutton = (e) => {
    e.preventDefault();
    deleteNewPriceHandler(selectedCard._id);
  };

  return (
    <div>
      {selectedCard && (
        <Form onSubmit={(e) => submitForm(e)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>one</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter one"
              value={one}
              onChange={(event) => ononeChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>two</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter two"
              value={two}
              onChange={(event) => ontwoChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>tree</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter tree"
              value={tree}
              onChange={(event) => ontreeChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>for</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter for"
              value={forr}
              onChange={(event) => onforrChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>five</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter five"
              value={five}
              onChange={(event) => onfiveChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>six</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter six"
              value={six}
              onChange={(event) => onsixChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>seven</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter seven"
              value={seven}
              onChange={(event) => onsevenChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>eight</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter eight"
              value={eight}
              onChange={(event) => oneightChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>nine</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter nine"
              value={nine}
              onChange={(event) => onnineChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>ten</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter ten"
              value={ten}
              onChange={(event) => ontenChangeHandler(event)}
            />
          </Form.Group>
          {/* Next 10 */}
          <Form.Group controlId="formBasicEmail">
            <Form.Label>11</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter one"
              value={oneone}
              onChange={(event) => ononeoneChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>12</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter two"
              value={twotwo}
              onChange={(event) => ontwotwoChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>13</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter tree"
              value={treetree}
              onChange={(event) => ontreetreeChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>14</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter for"
              value={forfor}
              onChange={(event) => onforforChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>15</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter five"
              value={fivefive}
              onChange={(event) => onfivefiveChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>16</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter six"
              value={sixsix}
              onChange={(event) => onsixsixChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>17</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter seven"
              value={sevenseven}
              onChange={(event) => onsevensevenChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>eight</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter eight"
              value={eighteight}
              onChange={(event) => oneigheightChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>19</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter nine"
              value={ninenine}
              onChange={(event) => onninenineChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>20</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter ten"
              value={tenten}
              onChange={(event) => ontentenChangeHandler(event)}
            />
          </Form.Group>

          <Button variant="primary" type="submit" style={{ margin: "2vh" }}>
            Submit
          </Button>
        </Form>
      )}
    </div>
  );
}

export default EditNewPrice;
