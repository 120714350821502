import { axios } from "../util/axios";

class NewCard {
  constructor(
    title,
    description,
    titleone,
    descriptionone,
    titletwo,
    descriptiontwo,
    titletree,
    descriptiontree,
    linkaddress,
    linkname,
    image
  ) {
    this.title = title;
    this.description = description;
    this.titleone = titleone;
    this.descriptionone = descriptionone;
    this.titletwo = titletwo;
    this.descriptiontwo = descriptiontwo;
    this.titletree = titletree;
    this.descriptiontree = descriptiontree;
    this.linkaddress = linkaddress;
    this.linkname = linkname;

    this.image = image;
  }
  create = (token) => {
    const fd = new FormData();
    fd.append("title", this.title);
    fd.append("description", this.description);
    fd.append("titleone", this.titleone);
    fd.append("descriptionone", this.descriptionone);
    fd.append("titletwo", this.titletwo);
    fd.append("descriptiontwo", this.descriptiontwo);
    fd.append("titletree", this.titletree);
    fd.append("descriptiontree", this.descriptiontree);
    fd.append("linkaddress", this.linkaddress);
    fd.append("linkname", this.linkname);

    fd.append("image", this.image);

    return axios.post(
      "newcard/create-newcard",
      // {
      //   name: this.name,
      //   price: this.price,
      //   isAvail: this.isAvail,
      // },
      fd,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  edit = (newcardId, token) => {
    const fd = new FormData();
    fd.append("title", this.title);
    fd.append("description", this.description);
    fd.append("titleone", this.titleone);
    fd.append("descriptionone", this.descriptionone);
    fd.append("titletwo", this.titletwo);
    fd.append("descriptiontwo", this.descriptiontwo);
    fd.append("titletree", this.titletree);
    fd.append("descriptiontree", this.descriptiontree);
    fd.append("linkaddress", this.linkaddress);
    fd.append("linkname", this.linkname);

    fd.append("image", this.image);

    return axios.patch(
      `newcard/edit-newcard/${newcardId}`,
      // {
      //   price: this.price,
      //   isAvail: this.isAvail,
      // },
      fd,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  delete = (newcardId, token) => {
    return axios.delete(`newcard/delete-newcard/${newcardId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  };
}

export default NewCard;
