import React, { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";

import LoginScreen from "./LoginScreen";
import { Modal, Button, Container } from "react-bootstrap";
import User from "../models/User";
import HomeScreen from "./HomeScreen";
import FooterComponent from "../components/FooterComponent";

function DashboardScreen(props) {
  const [isShowLoginForm, setIsShowLoginForm] = useState(false);

  const [userAuthData, setUserAuthData] = useReducer(HomeScreen, [], () => {
    const localData = localStorage.getItem("userAuthData");
    return localData ? JSON.parse(localData) : [];
  });
  const history = useHistory();

  // useEffect(() => {

  // }, [userAuthData]);

  const showModalHandler = () => {
    setIsShowLoginForm(true);
  };

  const closeModalHandler = () => {
    setIsShowLoginForm(false);
  };

  const clearCashHAndler = () => {
    localStorage.clear("userAuthData");
    history.push("/");
  };
  const adminlogin = localStorage.getItem("userAuthData", "userId");

  const loginHandler = async (username, password) => {
    const user = new User(username, password);
    try {
      let response = await user.login();
      let result = response.data;
      console.log(result);
      setUserAuthData(result);
      closeModalHandler();
      localStorage.setItem("userAuthData", JSON.stringify(result.token));
      history.push("/");
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };
  return (
    <Container>
      <center style={{ padding: "2vh", margin: "2vh" }}>
        <div>
          {/* Login Codes */}
          <div className="rtl">
            {!adminlogin ? (
              <Button
                style={{
                  margin: "2vh",
                  paddingLeft: "10vh",
                  paddingRight: "10vh",
                  fontSize: "3vh",
                }}
                onClick={showModalHandler}
              >
                login
              </Button>
            ) : null}
            {adminlogin ? (
              <Button
                style={{
                  margin: "2vh",
                  paddingLeft: "10vh",
                  paddingRight: "10vh",
                  fontSize: "3vh",
                }}
                variant="danger"
                onClick={clearCashHAndler}
              >
                Log Out
              </Button>
            ) : null}

            {/* <ProductsManagement userAuthData={userAuthData} /> */}
            <Modal
              show={isShowLoginForm}
              onHide={closeModalHandler}
              className="rtl"
            >
              <Modal.Header closeButton>
                <Modal.Title>Admin Login</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <LoginScreen loginHandler={loginHandler} />
              </Modal.Body>
            </Modal>

            {/* End Code of login */}
          </div>
        </div>
      </center>
      {/* <FooterComponent /> */}
    </Container>
  );
}

export default DashboardScreen;
