import React, { useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";

function EditCustomer({ NeweditCustomerHandler, selectedCard }) {
  const [number, setnumber] = useState(selectedCard ? selectedCard.number : "");
  const [facebook, setfacebook] = useState(
    selectedCard ? selectedCard.facebook : ""
  );
  const [instagram, setinstagram] = useState(
    selectedCard ? selectedCard.instagram : ""
  );
  const [twitter, settwitter] = useState(
    selectedCard ? selectedCard.instagram : ""
  );
  const [map, setmap] = useState(selectedCard ? selectedCard.map : "");

  const onnumberChangeHandler = (event) => {
    setnumber(event.target.value);
  };
  const onfacebookChangeHandler = (event) => {
    setfacebook(event.target.value);
  };

  const oninstagramChangeHandler = (event) => {
    setinstagram(event.target.value);
  };
  const ontwitterChangeHandler = (event) => {
    settwitter(event.target.value);
  };
  const onmapChangeHandler = (event) => {
    setmap(event.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log(selectedCard._id, number, facebook, instagram, twitter, map);
    NeweditCustomerHandler(
      selectedCard._id,
      number,
      facebook,
      instagram,
      twitter,
      map
    );
  };

  return (
    <div>
      {selectedCard && (
        <Form onSubmit={(e) => submitForm(e)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>phone number</Form.Label>
            <Form.Control
              type="string"
              placeholder="Enter phone number"
              value={number}
              maxLength={30}
              onChange={(event) => onnumberChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>facebook address</Form.Label>
            <InputGroup.Text id="basic-addon3">
              https://facebook.com/{facebook}
            </InputGroup.Text>
            <Form.Control
              type="facebook"
              placeholder="Enter facebook address"
              value={facebook}
              maxLength={30}
              onChange={(event) => onfacebookChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>instagram adress</Form.Label>
            <InputGroup.Text id="basic-addon3">
              https://instagram.com/{instagram}
            </InputGroup.Text>
            <Form.Control
              type="instagram"
              placeholder="Enter instagram"
              value={instagram}
              maxLength={30}
              onChange={(event) => oninstagramChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>twitter adress</Form.Label>
            <InputGroup.Text id="basic-addon3">
              https://twitter.com/{twitter}
            </InputGroup.Text>
            <Form.Control
              type="twitter"
              maxLength={20}
              placeholder="Enter instagram"
              value={twitter}
              onChange={(event) => ontwitterChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>map embed html </Form.Label>

            <Form.Control
              type="map"
              placeholder="Enter instagram"
              value={map}
              onChange={(event) => onmapChangeHandler(event)}
            />
          </Form.Group>

          <Button
            style={{
              paddingLeft: "4vh",
              paddingRight: "4vh",
              margin: "1vh",
              marginTop: "2vh",
            }}
            variant="success"
            type="submit"
          >
            Submit
          </Button>
        </Form>
      )}
    </div>
  );
}

export default EditCustomer;
