import React from "react";
// import pages
import HomeScreen from "./screens/HomeScreen";
import AboutUsScreen from "./screens/AboutUsScreen";
import ContactUsScreen from "./screens/ContactUsScreen";
import ServiseAndPriceScreen from "./screens/ServiseAndPriceScreen";
import NavbarComponent from "./components/NavbarComponent";
import AllPostScreen from "./screens/AllPostScreen";
import BlogPostDetailScreen from "./screens/BlogPostDetailScreen";
import CardsDetailScreen from "./screens/CardsDetailScreen";
import LoginScreen from "./screens/LoginScreen";
import DashboardScreen from "./screens/DashboardScreen";
import PaymentScreen from "./screens/PaymentScreen";
import CancellationScreen from "./screens/CancellationScreen";
import NomatchScreen from "./screens/NomatchScreen";

// import React Router dep
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import react bootstrap
// import "mdb-ui-kit/css/mdb.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App(props) {
  return (
    <Router>
      <div>
        <NavbarComponent />
        <Switch>
          <Route path="/" exact component={HomeScreen} />
          <Route path="/about" component={AboutUsScreen} />
          <Route path="/contact" component={ContactUsScreen} />
          <Route path="/services" component={ServiseAndPriceScreen} />
          <Route path="/Blog" component={AllPostScreen} />
          <Route path="/BlogPostDetails/:id" component={BlogPostDetailScreen} />
          <Route path="/CardPostDetails/:id" component={CardsDetailScreen} />
          <Route path="/Payment" exact component={PaymentScreen} />
          <Route path="/Login" component={LoginScreen} />
          <Route path="/randomCallSee/hereTodo" component={DashboardScreen} />
          <Route path="/Cancellation" component={CancellationScreen} />
          <Route path="*" component={NomatchScreen} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
