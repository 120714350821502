import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

function EditCard({
  deletePostblogHandler,
  selectedCard,
  NeweditCustomerHandler,
}) {
  const [title, settitle] = useState(selectedCard ? selectedCard.title : "");
  const [minitext, setminitext] = useState(
    selectedCard ? selectedCard.minitext : ""
  );
  const [description, setdescription] = useState(
    selectedCard ? selectedCard.description : ""
  );
  const [image, setimage] = useState(null);

  const ontitleChangeHandler = (event) => {
    settitle(event.target.value);
  };

  const ondescriptionChangeHandler = (event) => {
    setdescription(event.target.value);
  };
  const onminititleChangeHandler = (event) => {
    setminitext(event.target.value);
  };
  const onimageChangeHandler = (event) => {
    setimage(event.target.files[0]);
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log(selectedCard._id, title, description);
    NeweditCustomerHandler(
      selectedCard._id,
      title,
      description,
      minitext,
      image
    );
    // deletePostblogHandler(selectedCard._id);
  };

  const deletehandlerbutton = (e) => {
    e.preventDefault();
    deletePostblogHandler(selectedCard._id);
  };

  return (
    <div>
      {selectedCard && (
        <Form onSubmit={(e) => submitForm(e)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>title</Form.Label>
            <Form.Control
              type="title"
              placeholder="Enter title"
              value={title}
              onChange={(event) => ontitleChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>decription</Form.Label>
            <Form.Control
              type="description"
              as="textarea"
              rows={10}
              placeholder="Enter decription"
              value={description}
              onChange={(event) => ondescriptionChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>mini title</Form.Label>
            <Form.Control
              type="title"
              as="textarea"
              rows={10}
              placeholder="Enter decription"
              value={minitext}
              onChange={(event) => onminititleChangeHandler(event)}
            />
          </Form.Group>
          <Form.Group
            onChange={(event) => onimageChangeHandler(event)}
            controlId="formFileSm"
            className="mb-3"
          >
            <Form.Label>Small file input example</Form.Label>
            <Form.Control type="file" size="sm" />
          </Form.Group>
          <Button variant="primary" type="submit" style={{ margin: "2vh" }}>
            Submit
          </Button>
        </Form>
      )}
    </div>
  );
}

export default EditCard;
