import React, { useState, useEffect } from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import styles from "../components/styles/AllpostScreenStyle.module.css";
import { Link } from "react-router-dom";

// Add Axios - Model - Edit Component
import { axios } from "../util/axios";
import BlogPost from "./../models/BlogPost";
import CreateBlog from "../components/CreateBlog";
import EditBlog from "../components/EditBlog";
import { apiUrl } from "../env-vars";
import FooterComponent from "./../components/FooterComponent";

function AllPostScreen(props) {
  const userAuthData = localStorage.getItem("userAuthData", "userId");
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [isShowLoginForm, setIsShowLoginForm] = useState(false);
  const [showCreateAlert, setshowCreateAlert] = useState(false);
  const [showEditAlert, setshowEditAlert] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Fetch Data from sever
  const fetchData = async () => {
    let response = await axios.get("blog/get-all-blogposts");
    let result = response.data;
    // console.log(result);
    setCards(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const showEditModalHandler = () => {
    setIsShowEditModal(true);
  };
  const closeEditModalHandler = () => {
    setIsShowEditModal(false);
    setSelectedCard(null);
  };
  const selectCardHandler = (card) => {
    setSelectedCard(card);
    showEditModalHandler();
  };

  const showModalHandler = () => {
    setIsShowLoginForm(true);
  };

  const closeModalHandler = () => {
    setIsShowLoginForm(false);
  };

  // const selectNewPostHandler = () => {
  //   showEditModalHandler();
  // };

  // For Edit part code is

  const NewBlogPostHandler = async (title, minitext, description, image) => {
    const product = new BlogPost(title, minitext, description, image);
    try {
      let response = await product.create(userAuthData.replace(/['"]+/g, ""));
      let result = response.data;
      console.log(result);
      // window.alert(result.title + " " + result.minitext);
      setshowCreateAlert(true);
      closeModalHandler();

      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };

  const deletePostblogHandler = async (blogpostId) => {
    const blog = new BlogPost();
    try {
      let response = await blog.delete(
        blogpostId,
        userAuthData.replace(/['"]+/g, "")
      );
      let result = response.data;
      // window.alert(result.title + " با موفقیت حذف شد");
      handleShow();
      closeEditModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };

  const NeweditCustomerHandler = async (
    blogpostId,
    title,
    minitext,
    description,
    image
  ) => {
    const mycard = new BlogPost(title, description, minitext, image);
    try {
      let response = await mycard.edit(
        blogpostId,
        userAuthData.replace(/['"]+/g, "")
      );
      let result = response.data;
      console.log(result);
      // window.alert(result.title + " " + result.description);
      setshowEditAlert(true);
      closeEditModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };

  const pathCorrector = (path) => {
    return path.replace(/\\/g, "/");
  };
  return (
    <>
      <center>
        <div>
          {/* End Edit Admin button For card */}

          <div>
            {userAuthData ? (
              <center style={{ margin: "4vhs" }}>
                <Button
                  onClick={showModalHandler}
                  style={{
                    position: "relative",
                    top: "1vh",
                    margin: "2vh",
                  }}
                  variant="success"
                >
                  Create New Post
                </Button>
                <hr></hr>
              </center>
            ) : null}
            {/* Modal Part Start */}

            <Modal
              show={isShowLoginForm}
              onHide={closeModalHandler}
              className="rtl"
            >
              <Modal.Header closeButton>
                <Modal.Title>new blog post</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CreateBlog NewBlogPostHandler={NewBlogPostHandler} />
              </Modal.Body>
            </Modal>

            {/* Modal Part End */}
            {/* Modal Part Start */}

            <Modal
              show={isShowEditModal}
              onHide={closeEditModalHandler}
              className="rtl"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {selectedCard ? selectedCard.title : ""}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <EditBlog
                  deletePostblogHandler={deletePostblogHandler}
                  selectedCard={selectedCard}
                  NeweditCustomerHandler={NeweditCustomerHandler}
                />
              </Modal.Body>
            </Modal>

            {/* Modal Part End */}
          </div>

          <div class="container mt-5" style={{ margin: "0vh" }}>
            {/* Alert Create */}
            {showCreateAlert ? (
              <div>
                <Alert
                  style={{
                    margin: "2vh",
                  }}
                  variant="success"
                  onClose={() => setshowCreateAlert(false)}
                  dismissible
                >
                  <Alert.Heading>Your Post Created Successfully</Alert.Heading>
                  <h2></h2>
                </Alert>
              </div>
            ) : null}
            {/* Alert Create */}
            {/* Alert Edited */}
            {showEditAlert ? (
              <div>
                <Alert
                  style={{
                    margin: "2vh",
                  }}
                  variant="success"
                  onClose={() => setshowEditAlert(false)}
                  dismissible
                >
                  <Alert.Heading>Your Post Edited Successfully</Alert.Heading>
                  <h2></h2>
                </Alert>
              </div>
            ) : null}
            {/* Alert Edited */}
            {/* Alert For Delete */}
            {cards.map((card) => (
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Delete {card.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo,Are you sure you want to delete?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => deletePostblogHandler(card._id)}
                  >
                    Yes Im Sure
                  </Button>
                </Modal.Footer>
              </Modal>
            ))}
            {/* Alert */}
            <section class="">
              {/* {userAuthData ? (
              <Button
                onClick={deletePostblogHandler}
                style={{
                  position: "relative",
                  top: "-1vh",
                  margin: "2vh",
                }}
                variant="danger"
              >
                Delete this post
              </Button>
            ) : null} */}

              <center style={{ paddingRight: "1vh" }}>
                <div class="row">
                  {/* Start blog post */}
                  {cards.map((card) => (
                    <div class="col-lg-4 col-md-12 mb-lg-0 mb-4">
                      {/* Edit button */}
                      <div>
                        {userAuthData ? (
                          <Button
                            onClick={() => selectCardHandler(card)}
                            style={{
                              position: "relative",
                              top: "-1vh",
                              paddingLeft: "10vh",
                              paddingRight: "10vh",
                              margin: "3vh",
                            }}
                            variant="warning"
                          >
                            Edit This post
                          </Button>
                        ) : null}
                        {userAuthData ? (
                          <Button onClick={handleShow} variant="danger">
                            Delete this post
                          </Button>
                        ) : null}
                      </div>
                      {/* Edit button */}
                      <div
                        class="card hoverable"
                        style={{ borderRadius: "3vh", margin: "1vh" }}
                      >
                        <img
                          class="card-img-top"
                          src={apiUrl + "/" + pathCorrector(card.image)}
                          alt="Card image cap"
                          style={{
                            borderRadius: "2vh",
                            maxWidth: "100%",
                            minWidth: "100%",
                            maxHeight: "40vh",
                            minHeight: "40vh",
                          }}
                        />

                        <div class="card-body">
                          <span className={styles.taghed}>{card.title}</span>
                          <p
                            class="card-title text-muted font-small mt-3 mb-2"
                            className={styles.tagparagraf}
                          >
                            {card.minitext}
                          </p>
                          <span
                            style={{
                              border: "none",
                              backgroundColor: "#fedade",
                              marginTop: "2vh",
                              paddingRight: "4vh",
                              paddingLeft: "4vh",
                              paddingTop: "0.2vh",
                              paddingBottom: "1vh",
                              color: "#fff",
                              fontSize: "3vh",
                              borderRadius: "2vh",
                            }}
                          >
                            <Link
                              style={{
                                fontSize: "2vh",
                                textDecoration: "none",
                                color: "white",
                              }}
                              to={`BlogPostDetails/${card._id}`}
                            >
                              Read more
                              <i class="fa fa-angle-right ml-2"></i>
                            </Link>
                          </span>
                        </div>
                      </div>

                      {/* end blog post  */}
                      {/* End model */}
                    </div>
                  ))}
                </div>
              </center>
            </section>
          </div>
          <FooterComponent />
        </div>
      </center>
    </>
  );
}

export default AllPostScreen;
