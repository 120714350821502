import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

function CreateCancel({ NewcancelHandler }) {
  const [titlecancel, settitlecancel] = useState("");
  const [description, setdescription] = useState("");

  const ontitlecancelChangeHandler = (event) => {
    settitlecancel(event.target.value);
  };
  const ondescriptionChangeHandler = (event) => {
    setdescription(event.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log(titlecancel, description);
    NewcancelHandler(titlecancel, description);
  };

  return (
    <div>
      <Form onSubmit={(e) => submitForm(e)}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>title payment</Form.Label>
          <Form.Control
            type="titlepayment"
            placeholder="Enter title payment role"
            value={titlecancel}
            onChange={(event) => ontitlecancelChangeHandler(event)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label> description</Form.Label>
          <Form.Control
            type="description"
            placeholder="Enter description payment role"
            value={description}
            onChange={(event) => ondescriptionChangeHandler(event)}
          />
        </Form.Group>

        <Button
          style={{
            margin: "1vh",
            paddingLeft: "10vh",
            paddingRight: "10vh",
          }}
          variant="success"
          type="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default CreateCancel;
