import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

function EditAbout({ selectedCard, NeweditAboutHandler }) {
  const [aboutdescription, setaboutdescription] = useState(
    selectedCard ? selectedCard.aboutdescription : ""
  );
  const [image, setimage] = useState(null);

  const onaboutdescriptionChangeHandler = (event) => {
    setaboutdescription(event.target.value);
  };

  const onimageChangeHandler = (event) => {
    setimage(event.target.files[0]);
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log(selectedCard._id, aboutdescription);
    NeweditAboutHandler(selectedCard._id, aboutdescription, image);
    // deletePostblogHandler(selectedCard._id);
  };

  return (
    <div>
      {selectedCard && (
        <Form onSubmit={(e) => submitForm(e)}>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>decription</Form.Label>
            <Form.Control
              type="aboutdescription"
              as="textarea"
              rows={14}
              maxLength={3250}
              placeholder="Enter aboutdescription"
              value={aboutdescription}
              onChange={(event) => onaboutdescriptionChangeHandler(event)}
            />
          </Form.Group>

          <Form.Group
            onChange={(event) => onimageChangeHandler(event)}
            controlId="formFileSm"
            className="mb-3"
          >
            <Form.Label>Small file input example</Form.Label>
            <Form.Control type="file" size="sm" />
          </Form.Group>
          <Button
            variant="success"
            type="submit"
            style={{
              paddingLeft: "4vh",
              paddingRight: "4vh",
              margin: "1vh",
            }}
          >
            Submit
          </Button>
        </Form>
      )}
    </div>
  );
}

export default EditAbout;
