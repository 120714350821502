import React, { useState } from "react";
import { Form, Button, Image } from "react-bootstrap";
import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";

function CreateBlog({ NewBlogPostHandler }) {
  const [title, settitle] = useState("");
  const [minititle, setminititle] = useState("");
  const [description, setdescription] = useState("");
  const [image, setImage] = useState(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const ontitleChangeHandler = (event) => {
    settitle(event.target.value);
  };
  const onminititleChangeHandler = (event) => {
    setminititle(event.target.value);
  };

  const ondescriptionChangeHandler = (event) => {
    setdescription(event.target.value);
  };
  const onImageChangeHandler = (event) => {
    setImage(event.target.files[0]);
  };

  const pathCorrector = (path) => {
    return path.replace(/\\/g, "/");
  };

  const submitForm = (e) => {
    e.preventDefault();
    // console.log(title, description);
    NewBlogPostHandler(title, minititle, description, image);
  };

  return (
    <div>
      <Form onSubmit={(e) => submitForm(e)}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>title</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter title"
            value={title}
            onChange={(event) => ontitleChangeHandler(event)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>mini title for Preview</Form.Label>
          <Form.Control
            as="textarea"
            rows={6}
            type="minititle"
            placeholder="Enter mini title"
            value={minititle}
            onChange={(event) => onminititleChangeHandler(event)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>decription</Form.Label>

          <Form.Control
            as="textarea"
            rows={10}
            type="description"
            placeholder="Enter decription"
            value={description}
            onChange={(event) => ondescriptionChangeHandler(event)}
          />
        </Form.Group>
        {/* image */}
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Default file input example</Form.Label>
          <Form.Control
            type="file"
            onChange={(event) => onImageChangeHandler(event)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default CreateBlog;
