import { axios } from "../util/axios";

class About {
  constructor(aboutdescription, image) {
    this.aboutdescription = aboutdescription;
    this.image = image;
  }
  create = (token) => {
    const fd = new FormData();

    fd.append("aboutdescription", this.aboutdescription);
    fd.append("image", this.image);

    return axios.post(
      "about/create-about",
      // {
      //   name: this.name,
      //   price: this.price,
      //   isAvail: this.isAvail,
      // },
      fd,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  edit = (blogpostId, token) => {
    const fd = new FormData();

    fd.append("aboutdescription", this.aboutdescription);
    fd.append("image", this.image);

    return axios.patch(
      `about/edit-about/${blogpostId}`,
      // {
      //   price: this.price,
      //   isAvail: this.isAvail,
      // },
      fd,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  delete = (blogpostId, token) => {
    return axios.delete(`blog/delete-blogpost/${blogpostId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  };
}

export default About;
