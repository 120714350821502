import { axios } from "../util/axios";

class Contact {
  constructor(description, address, number, email) {
    this.description = description;
    this.address = address;
    this.number = number;
    this.email = email;
  }

  edit = (contactId, token) => {
    const fd = new FormData();

    fd.append("description", this.description);
    fd.append("address", this.address);
    fd.append("number", this.number);
    fd.append("email", this.email);

    return axios.patch(
      `contact/edit-contact/${contactId}`,
      {
        description: this.description,
        address: this.address,
        number: this.number,
        email: this.email,
      },

      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
}

export default Contact;
