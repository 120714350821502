import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

function CreatePayment({ NewpaymentHandler }) {
  const [titlepayment, settitlepayment] = useState("");
  const [description, setdescription] = useState("");

  const ontitlepaymentChangeHandler = (event) => {
    settitlepayment(event.target.value);
  };
  const ondescriptionChangeHandler = (event) => {
    setdescription(event.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log(titlepayment, description);
    NewpaymentHandler(titlepayment, description);
  };

  return (
    <div>
      <Form onSubmit={(e) => submitForm(e)}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>title payment</Form.Label>
          <Form.Control
            type="titlepayment"
            placeholder="Enter title payment role"
            value={titlepayment}
            onChange={(event) => ontitlepaymentChangeHandler(event)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label> description</Form.Label>
          <Form.Control
            type="description"
            placeholder="Enter description payment role"
            value={description}
            onChange={(event) => ondescriptionChangeHandler(event)}
          />
        </Form.Group>

        <Button
          style={{
            margin: "1vh",
            paddingLeft: "10vh",
            paddingRight: "10vh",
          }}
          variant="success"
          type="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default CreatePayment;
