import React, { useState, useEffect } from "react";
import { axios } from "../util/axios";
import { apiUrl } from "../env-vars";
import FooterComponent from "../components/FooterComponent";
// `blog/get-blogpost-by-id/${match.params.id}`

function BlogPostDetailScreen({ match }) {
  const [cards, setCards] = useState([]);
  // Fetch Data from sever
  const fetchData = async () => {
    const response = await axios.get(
      `blog/get-blogpost-by-id/${match.params.id}`
    );
    const result = await response.data;
    setCards([result]);
    console.log(result);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const pathCorrector = (path) => {
    return path.replace(/\\/g, "/");
  };
  return (
    <div>
      {cards.map((card) => (
        <div class="container mt-5">
          <section class="mx-md-5 dark-grey-text">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-cascade wider reverse">
                  <div class="view view-cascade overlay">
                    <img
                      class="card-img-top"
                      src={apiUrl + "/" + pathCorrector(card.image)}
                      alt="Sample image"
                    />
                    <a href="#!">
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>

                  <div class="card-body card-body-cascade text-center">
                    <h3 class="font-weight-bold">
                      <a>{card.title}</a>
                    </h3>
                  </div>
                </div>

                <div>
                  <div class="mt-5">
                    <p>{card.description}</p>
                  </div>
                </div>
              </div>
            </div>

            <hr class="mb-5 mt-4" />
          </section>
        </div>
      ))}
      <FooterComponent />
    </div>
  );
}

export default BlogPostDetailScreen;
