import { axios } from "../util/axios";

class Cancel {
  constructor(titlecancel, description) {
    this.titlecancel = titlecancel;
    this.description = description;
  }
  create = (token) => {
    const fd = new FormData();
    fd.append("titlecancel", this.titlecancel);
    fd.append("description", this.description);

    return axios.post(
      "cancel/create-cancel",
      // {
      //   name: this.name,
      //   price: this.price,
      //   isAvail: this.isAvail,
      // },
      fd,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  edit = (cancelId, token) => {
    const fd = new FormData();
    fd.append("titlecancel", this.titlecancel);
    fd.append("description", this.description);

    return axios.patch(
      `cancel/edit-cancel/${cancelId}`,
      // {
      //   price: this.price,
      //   isAvail: this.isAvail,
      // },
      fd,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  delete = (cancelId, token) => {
    return axios.delete(`cancel/delete-cancel/${cancelId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  };
}

export default Cancel;
