import React, { useEffect, useState } from "react";
import { Facebook, Instagram, Twitter } from "react-bootstrap-icons";
import "./styles/Footer.css";
import { Modal, Button, Alert, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// Fetch dta needs
import { axios } from "../util/axios";
import Footer from "../models/Footer";
import EditFooter from "../components/EditFooter";

function FooterComponent({ userAuthData }) {
  const [cards, setCards] = useState([]);
  const [links, setlinks] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [showEditAlert, setshowEditAlert] = useState(false);

  // Fetch Data from sever
  const fetchDataa = async () => {
    let response = await axios.get("newcard/get-all-newcards");
    let result = response.data;
    // console.log(result);
    setlinks(result);
  };
  useEffect(() => {
    fetchDataa();
  }, []);
  // Fetch Data from sever
  const fetchData = async () => {
    let response = await axios.get("footer/get-all-footers");
    let result = response.data;
    // console.log(result);
    setCards(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const showEditModalHandler = () => {
    setIsShowEditModal(true);
  };
  const closeEditModalHandler = () => {
    setIsShowEditModal(false);
    setSelectedCard(null);
  };
  const selectCardHandler = (card) => {
    setSelectedCard(card);
    showEditModalHandler();
  };

  const NeweditCustomerHandler = async (
    footerId,
    number,
    facebook,
    instagram,
    twitter,
    map
  ) => {
    const mycustomer = new Footer(number, facebook, instagram, twitter, map);
    try {
      let response = await mycustomer.edit(
        footerId,
        userAuthData.replace(/['"]+/g, "")
      );
      let result = response.data;
      console.log(result);
      // window.alert(result.title + " " + result.description);
      setshowEditAlert(true);
      closeEditModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };

  return (
    <>
      {/* Alert Edited */}
      {showEditAlert ? (
        <div>
          <Alert
            style={{
              margin: "5vh",
            }}
            variant="success"
            onClose={() => setshowEditAlert(false)}
            dismissible
          >
            <Alert.Heading>Footer Edited Successfully</Alert.Heading>
            <h2></h2>
          </Alert>
        </div>
      ) : null}
      {/* Alert Edited */}
      <div style={{ paddingTop: "50px" }}>
        {/* Modal Part Start */}
        {cards.map((card) => (
          <Modal
            show={isShowEditModal}
            onHide={closeEditModalHandler}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {selectedCard ? selectedCard.title : ""}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* EditCard */}
              <EditFooter
                NeweditCustomerHandler={NeweditCustomerHandler}
                selectedCard={selectedCard}
              />
              {/* EditCard */}
            </Modal.Body>
          </Modal>
        ))}
        {/* Modal Part End */}
        {cards.map((card) => (
          <div className="main-footer" style={{ margin: "auto" }}>
            <div className="container">
              {/* Edit Admin Button For card */}

              {userAuthData ? (
                <center style={{ margin: "4vhs" }}>
                  <Button
                    variant="warning"
                    onClick={() => selectCardHandler(card)}
                    style={{
                      // backgroundColor: "#F6A9A9",
                      paddingLeft: "20%",
                      paddingRight: "20%",
                      paddingTop: "1vh",
                      paddingBottom: "1vh",
                      borderRadius: "4vh",
                      cursor: "pointer",
                      position: "relative",
                      top: "-3vh",
                    }}
                  >
                    EDIT
                  </Button>
                </center>
              ) : null}
              {/* End Edit Admin button For card */}
              <center>
                <div className="row">
                  {/* Column1 */}
                  <div className="col-lg-4">
                    <h4>Phone Number</h4>
                    <h1 className="list-unstyled">
                      <li>{card.number}</li>
                    </h1>
                  </div>
                  {/* Column2 */}
                  <div className="col-lg-4">
                    <h4>Social Media</h4>
                    <ui className="list-unstyled">
                      {/* <a href={card.facebook}>
                    <Facebook
                      color="white"
                      size="5vh"
                      style={{ margin: "2vh" }}
                    />
                  </a> */}
                      <span>
                        <Link
                          to={{
                            pathname: ` https://facebook.com/${card.facebook}`,
                          }}
                          target="_blank"
                        >
                          <Facebook
                            color="white"
                            size="5vh"
                            style={{ margin: "2vh" }}
                          />
                        </Link>
                      </span>
                      <span>
                        <Link
                          to={{
                            pathname: ` https://instagram.com/${card.instagram}`,
                          }}
                          target="_blank"
                        >
                          <Instagram
                            color="white"
                            size="5vh"
                            style={{ margin: "2vh" }}
                          />
                        </Link>
                      </span>
                      <span>
                        <Link
                          to={{
                            pathname: ` https://twitter.com/${card.twitter}`,
                          }}
                          target="_blank"
                        >
                          <Twitter
                            color="white"
                            size="5vh"
                            style={{ margin: "2vh" }}
                          />
                        </Link>
                      </span>
                    </ui>
                  </div>
                  {/* Column3 */}
                  <div className="col-lg-4">
                    <h4 style={{ color: "#fff" }}>Links</h4>
                    <hr />
                    <Row xs={2} md={2}>
                      {links.map((link) => (
                        <Col style={{ color: "#F36C6C" }}>
                          <a
                            style={{
                              cursor: "pointer",
                              fontWeight: "bolder",
                              fontSize: "1.5vh",
                              float: "left",
                              paddingLeft: "1vh",
                            }}
                            onClick={() => {
                              window.location.href = `/CardPostDetails/${link._id}`;
                            }}
                          >
                            <li>{link.title}</li>
                          </a>
                        </Col>
                      ))}
                    </Row>
                    {/* <ui className="list-unstyled">
                      {links.map((link) => (
                        <a
                          style={{
                            cursor: "pointer",
                            fontWeight: "bolder",
                            fontSize: "3vh",
                          }}
                          onClick={() => {
                            window.location.href = `/CardPostDetails/${link._id}`;
                          }}
                        >
                          <li>{link.title}</li>
                        </a>
                      ))}
                    </ui> */}
                  </div>
                </div>
              </center>
              <hr />

              <div
                id="map-container-google-12"
                className="z-depth-1-half map-container-3"
              >
                <div>
                  <iframe
                    style={{
                      border: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "4vh",
                    }}
                    src={card.map}
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <div className="row">
                <p className="col-sm">
                  &copy;{new Date().getFullYear()} C.C HAIR & BROW | All rights
                  reserved | Privacy | Created and Support By
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "#F37071",
                      opacity: "0.7",
                    }}
                    to={{
                      pathname: "https://www.lacode.company",
                    }}
                    target="_blank"
                  >
                    <span
                      style={{
                        fontWeight: "bolder",
                        cursor: "pointer",
                        paddingLeft: "1vh",
                      }}
                    >
                      LACODE.COMPANY
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default FooterComponent;
