import { axios } from "../util/axios";

class User {
  constructor(username, password) {
    this.username = username;
    this.password = password;
  }
  login = () => {
    return axios.post("/user/user-login", {
      username: this.username,
      password: this.password,
    });
  };
}

export default User;
