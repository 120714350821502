import React, { useState, useEffect } from "react";
import { Modal, Button, Alert } from "react-bootstrap";

import { axios } from "../util/axios";
import NewPrice from "../models/NewPrice";
import CreateNewPrice from "./CreateNewPrice";
import EditNewPrice from "./EditNewPrice";

function ServiceAndPriceComponent(props) {
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [isShowCreateModal, setIsShowCreateModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showCreateAlert, setshowCreateAlert] = useState(false);
  const [showEditAlert, setshowEditAlert] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const userAuthData = localStorage.getItem("userAuthData", "userId");

  // Fetch Data from sever
  const fetchData = async () => {
    let response = await axios.get("newprice/get-all-newprices");
    let result = response.data;
    // console.log(result);
    setCards(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const showEditModalHandler = () => {
    setIsShowEditModal(true);
  };
  const closeEditModalHandler = () => {
    setIsShowEditModal(false);
    setSelectedCard(null);
  };
  const showCreateModalHandler = () => {
    setIsShowCreateModal(true);
  };
  const closeCreateModalHandler = () => {
    setIsShowCreateModal(false);
    setSelectedCard(null);
  };

  const selectCardHandler = (card) => {
    setSelectedCard(card);
    showEditModalHandler();
  };

  // new header image and titel create handler function
  const NewNewPriceHandler = async (
    one,
    two,
    tree,
    forr,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    oneone,
    twotwo,
    treetree,
    forfor,
    fivefive,
    sixsix,
    sevenseven,
    eighteight,
    ninenine,
    tenten
  ) => {
    const newprice = new NewPrice(
      one,
      two,
      tree,
      forr,
      five,
      six,
      seven,
      eight,
      nine,
      ten,
      oneone,
      twotwo,
      treetree,
      forfor,
      fivefive,
      sixsix,
      sevenseven,
      eighteight,
      ninenine,
      tenten
    );
    try {
      let response = await newprice.create(userAuthData.replace(/['"]+/g, ""));
      let result = response.data;
      console.log(result);
      // window.alert(result.one + " " + result.one);
      setshowCreateAlert(true);
      closeCreateModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error);
    }
  };
  // Edit Prices tables

  const NewEditPriceHandler = async (
    newpriceId,
    one,
    two,
    tree,
    forr,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    oneone,
    twotwo,
    treetree,
    forfor,
    fivefive,
    sixsix,
    sevenseven,
    eighteight,
    ninenine,
    tenten
  ) => {
    const newprice = new NewPrice(
      one,
      two,
      tree,
      forr,
      five,
      six,
      seven,
      eight,
      nine,
      ten,
      oneone,
      twotwo,
      treetree,
      forfor,
      fivefive,
      sixsix,
      sevenseven,
      eighteight,
      ninenine,
      tenten
    );
    try {
      let response = await newprice.edit(
        newpriceId,
        userAuthData.replace(/['"]+/g, "")
      );
      let result = response.data;
      console.log(result);
      // window.alert(result.one + "edited Success");
      setshowEditAlert(true);
      closeEditModalHandler();
      fetchData();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };

  // Delete Price tablse
  const deleteNewPriceHandler = async (newpriceId) => {
    const newprice = new NewPrice();
    try {
      let response = await newprice.delete(
        newpriceId,
        userAuthData.replace(/['"]+/g, "")
      );
      let result = response.data;
      // window.alert(result.one + " با موفقیت حذف شد");
      closeCreateModalHandler();
      fetchData();
      handleClose();
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };
  // New Allert delete yes or no

  return (
    <center>
      {/* Alert Create */}
      {showCreateAlert ? (
        <div>
          <Alert
            style={{
              margin: "2vh",
            }}
            variant="success"
            onClose={() => setshowCreateAlert(false)}
            dismissible
          >
            <Alert.Heading>Your Table Created Successfully</Alert.Heading>
            <h2></h2>
          </Alert>
        </div>
      ) : null}
      {/* Alert Create */}
      {/* Alert Edited */}
      {showEditAlert ? (
        <div>
          <Alert
            style={{
              margin: "2vh",
            }}
            variant="success"
            onClose={() => setshowEditAlert(false)}
            dismissible
          >
            <Alert.Heading>Your Table Edited Successfully</Alert.Heading>
            <h2></h2>
          </Alert>
        </div>
      ) : null}
      {/* Alert Edited */}
      {/* Alert For Delete */}
      {cards.map((card) => (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete {card.one}</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo,Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => deleteNewPriceHandler(card._id)}
            >
              Yes Im Sure
            </Button>
          </Modal.Footer>
        </Modal>
      ))}
      {/* Alert */}
      <div className="container my-5 p-3 z-depth-1">
        {/* Modal Part Start */}

        <Modal
          show={isShowCreateModal}
          onHide={closeCreateModalHandler}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              {selectedCard ? selectedCard.title : ""}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* EditCard */}
            <CreateNewPrice NewNewPriceHandler={NewNewPriceHandler} />
            {/* EditCard */}
          </Modal.Body>
        </Modal>

        {/* Modal Part End */}
        {/* Modal Part Start */}
        {cards.map((card) => (
          <Modal
            show={isShowEditModal}
            onHide={closeEditModalHandler}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {selectedCard ? selectedCard.title : ""}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* EditCard */}
              <EditNewPrice
                NewEditPriceHandler={NewEditPriceHandler}
                deleteNewPriceHandler={deleteNewPriceHandler}
                selectedCard={selectedCard}
              />
              {/* <EditPrice
                NeweditPriceHandler={NeweditPriceHandler}
                selectedCard={selectedCard}
              /> */}
              {/* EditCard */}
            </Modal.Body>
          </Modal>
        ))}
        {/* Modal Part End */}
        <section
          className="text-center dark-grey-text"
          style={{
            backgroundColor: "#fedade",
            borderRadius: "5vh",
            padding: "1vh",
            marginTop: "-4vh",
          }}
        >
          <h3 className="font-weight-bold pb-2">Price and Services</h3>
          <hr className="w-header my-4" />
          {/* {cards.map((card) => (
            <p className="text-muted w-responsive mx-auto mb-5">
              {card.description}
            </p>
          ))} */}
          <div className="row">
            {/* Edit Admin Button For card */}

            {userAuthData ? (
              <center style={{ margin: "4vhs" }}>
                <Button
                  variant="success"
                  onClick={showCreateModalHandler}
                  style={{
                    // backgroundColor: "#F6A9A9",
                    paddingLeft: "20%",
                    paddingRight: "20%",
                    paddingTop: "1vh",
                    paddingBottom: "1vh",
                    borderRadius: "4vh",
                    cursor: "pointer",
                    margin: "2vh",
                  }}
                >
                  Create New Price Table
                </Button>
              </center>
            ) : null}
            {/* End Edit Admin button For card */}
            {cards.map((card) => (
              <div
                style={{
                  margin: "auto",
                }}
                className="col-lg-5 col-md-12 mb-4"
              >
                {/* Edit Admin Button For card */}

                {userAuthData ? (
                  <center style={{ margin: "4vhs" }}>
                    <Button
                      variant="warning"
                      onClick={() => selectCardHandler(card)}
                      style={{
                        // backgroundColor: "#F6A9A9",
                        paddingLeft: "20%",
                        paddingRight: "20%",
                        paddingTop: "1vh",
                        paddingBottom: "1vh",
                        borderRadius: "4vh",
                        cursor: "pointer",
                        margin: "2vh",
                      }}
                    >
                      EDIT
                    </Button>
                  </center>
                ) : null}
                {/* End Edit Admin button For card */}
                {/* Edit Admin Button For card */}

                {userAuthData ? (
                  <center style={{ margin: "4vhs" }}>
                    <Button
                      variant="danger"
                      onClick={handleShow}
                      style={{
                        // backgroundColor: "#F6A9A9",
                        paddingLeft: "20%",
                        paddingRight: "20%",
                        paddingTop: "1vh",
                        paddingBottom: "1vh",
                        borderRadius: "4vh",
                        cursor: "pointer",
                        margin: "2vh",
                      }}
                    >
                      Delete it
                    </Button>
                  </center>
                ) : null}
                {/* End Edit Admin button For card */}
                <div
                  style={{ backgroundColor: "white", borderRadius: "5vh" }}
                  className="pricing-card"
                >
                  <div
                    className="card-body"
                    style={{
                      margin: "auto",
                      justifyContent: "center",
                      maxHeight: "800px",
                      minHeight: "800px",
                    }}
                  >
                    <h6 className="font-weight-bold text-uppercase mb-3">
                      {card.one}
                    </h6>

                    <ul
                      className="mb-0"
                      style={{
                        listStyleType: "none",
                        margin: "auto",
                        padding: "1vh",
                      }}
                    >
                      <li>
                        <p>{card.two}</p>
                      </li>
                      <li>
                        <p>{card.tree}</p>
                      </li>
                      <li>
                        <p>{card.forr}</p>
                      </li>
                      <li>
                        <p>{card.five}</p>
                      </li>
                      <li>
                        <p>{card.six}</p>
                      </li>
                      <li>
                        <p>{card.seven}</p>
                      </li>
                      <li>
                        <p>{card.eight}</p>
                      </li>
                      <li>
                        <p>{card.nine}</p>
                      </li>
                      <li>
                        <p>{card.ten}</p>
                      </li>
                      <li>
                        <p>{card.twotwo}</p>
                      </li>
                      <li>
                        <p>{card.treetree}</p>
                      </li>
                      <li>
                        <p>{card.forfor}</p>
                      </li>
                      <li>
                        <p>{card.fivefive}</p>
                      </li>
                      <li>
                        <p>{card.sixsix}</p>
                      </li>
                      <li>
                        <p>{card.sevenseven}</p>
                      </li>
                      <li>
                        <p>{card.eighteight}</p>
                      </li>
                      <li>
                        <p>{card.ninenine}</p>
                      </li>
                      <li>
                        <p>{card.tenten}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </center>
  );
}

export default ServiceAndPriceComponent;
