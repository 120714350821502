import React, { useState } from "react";
import { Form, Button, Image } from "react-bootstrap";

function CreateNewCard({ NewNewCardHandler }) {
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [titleone, settitleone] = useState("");
  const [descriptionone, setdescriptionone] = useState("");
  const [titletwo, settitletwo] = useState("");
  const [descriptiontwo, setdescriptiontwo] = useState("");
  const [titletree, settitletree] = useState("");
  const [descriptiontree, setdescriptiontree] = useState("");
  const [linkaddress, setlinkaddress] = useState("");
  const [linkname, setlinkname] = useState("");

  const [image, setImage] = useState(null);

  const ontitleChangeHandler = (event) => {
    settitle(event.target.value);
  };

  const ondescriptionChangeHandler = (event) => {
    setdescription(event.target.value);
  };
  const ontitleoneChangeHandler = (event) => {
    settitleone(event.target.value);
  };

  const ondescriptiononeChangeHandler = (event) => {
    setdescriptionone(event.target.value);
  };
  const ontitletwoChangeHandler = (event) => {
    settitletwo(event.target.value);
  };

  const ondescriptiontwoChangeHandler = (event) => {
    setdescriptiontwo(event.target.value);
  };
  const ontitletreeChangeHandler = (event) => {
    settitletree(event.target.value);
  };

  const ondescriptiontreeChangeHandler = (event) => {
    setdescriptiontree(event.target.value);
  };
  const onlinkaddressChangeHandler = (event) => {
    setlinkaddress(event.target.value);
  };

  const onlinknameChangeHandler = (event) => {
    setlinkname(event.target.value);
  };

  const onImageChangeHandler = (event) => {
    setImage(event.target.files[0]);
  };

  const pathCorrector = (path) => {
    return path.replace(/\\/g, "/");
  };

  const submitForm = (e) => {
    e.preventDefault();
    // console.log(title, description);
    NewNewCardHandler(
      title,
      description,
      titleone,
      descriptionone,
      titletwo,
      descriptiontwo,
      titletree,
      descriptiontree,
      linkaddress,
      linkname,
      image
    );
  };

  return (
    <div>
      <Form onSubmit={(e) => submitForm(e)}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>title</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter title"
            value={title}
            onChange={(event) => ontitleChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>decription</Form.Label>

          <Form.Control
            as="textarea"
            rows={10}
            type="description"
            placeholder="Enter decription"
            value={description}
            onChange={(event) => ondescriptionChangeHandler(event)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>title one</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter title"
            value={titleone}
            onChange={(event) => ontitleoneChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>decription one</Form.Label>

          <Form.Control
            as="textarea"
            rows={10}
            type="description"
            placeholder="Enter decription"
            value={descriptionone}
            onChange={(event) => ondescriptiononeChangeHandler(event)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>title two</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter title"
            value={titletwo}
            onChange={(event) => ontitletwoChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>decription two</Form.Label>

          <Form.Control
            as="textarea"
            rows={10}
            type="description"
            placeholder="Enter decription"
            value={descriptiontwo}
            onChange={(event) => ondescriptiontwoChangeHandler(event)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>title tree</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter title"
            value={titletree}
            onChange={(event) => ontitletreeChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>decription tree</Form.Label>

          <Form.Control
            as="textarea"
            rows={10}
            type="description"
            placeholder="Enter decription"
            value={descriptiontree}
            onChange={(event) => ondescriptiontreeChangeHandler(event)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>link address</Form.Label>
          <Form.Control
            type="title"
            placeholder="Enter title"
            value={linkaddress}
            onChange={(event) => onlinkaddressChangeHandler(event)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>link name</Form.Label>

          <Form.Control
            as="textarea"
            rows={10}
            type="description"
            placeholder="Enter decription"
            value={linkname}
            onChange={(event) => onlinknameChangeHandler(event)}
          />
        </Form.Group>
        {/* image */}
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Default file input example</Form.Label>
          <Form.Control
            type="file"
            onChange={(event) => onImageChangeHandler(event)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default CreateNewCard;
