import React, { useState, useEffect } from "react";
import { axios } from "../util/axios";
import { apiUrl } from "../env-vars";
import FooterComponent from "./../components/FooterComponent";
import { Link } from "react-router-dom";

function CardsDetailScreen({ match }) {
  const [cards, setCards] = useState([]);
  // Fetch Data from sever
  const fetchData = async () => {
    const response = await axios.get(
      `newcard/get-newcard-by-id/${match.params.id}`
    );
    const result = await response.data;
    setCards([result]);
    console.log(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const pathCorrector = (path) => {
    return path.replace(/\\/g, "/");
  };

  return (
    <div>
      {cards.map((card) => (
        <div class="container my-5">
          <section class="dark-grey-text">
            <h3 class="text-center font-weight-bold mb-4 pb-2">{card.title}</h3>
            <p
              style={{
                padding: "2vh",
                fontWeight: "bold",
              }}
              class="text-center text-muted w-responsive mx-auto mb-5"
            >
              {card.description}
            </p>
            <li
              style={{
                margin: "5vh",
              }}
              class="d-flex justify-content-center pl-4"
            >
              <div>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "#F36C6C",
                  }}
                  to={{
                    pathname: `http://${card.linkaddress}`,
                  }}
                  target="_blank"
                >
                  <h5
                    style={{
                      cursor: "pointer",
                      fontWeight: "bolder",
                      fontSize: "5vh",
                      backgroundColor: "#fedade",
                      padding: "2vh",
                      borderRadius: "5vh",
                    }}
                    class="font-weight-bold mb-3"
                  >
                    {card.linkname}
                  </h5>
                </Link>
              </div>
            </li>
            <div class="row">
              <div class="col-lg-5 mb-lg-0 mb-5">
                <img
                  src={apiUrl + "/" + pathCorrector(card.image)}
                  class="img-fluid rounded z-depth-1"
                  alt="Sample project image"
                />
              </div>

              <div class="col-lg-7">
                <ul class="list-unstyled fa-ul mb-0">
                  <li class="d-flex justify-content-center pl-4">
                    <div>
                      <h5 class="font-weight-bold mb-3">{card.titleone}</h5>
                      <p
                        style={{
                          padding: "2vh",
                          fontWeight: "bold",
                        }}
                        class="text-muted"
                      >
                        {card.descriptionone}
                      </p>
                    </div>
                  </li>
                  <li class="d-flex justify-content-center pl-4">
                    <div>
                      <h5 class="font-weight-bold mb-3">{card.titletwo}</h5>
                      <p
                        style={{
                          padding: "2vh",
                          fontWeight: "bold",
                        }}
                        class="text-muted"
                      >
                        {card.descriptiontwo}
                      </p>
                    </div>
                  </li>
                  <li class="d-flex justify-content-center pl-4">
                    <div>
                      <h5 class="font-weight-bold mb-3">{card.titletree}</h5>
                      <p
                        style={{
                          padding: "2vh",
                          fontWeight: "bold",
                        }}
                        class="text-muted"
                      >
                        {card.descriptiontree}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <hr class="my-5"></hr>
          </section>
        </div>
      ))}
      <FooterComponent />
    </div>
  );
}

export default CardsDetailScreen;
